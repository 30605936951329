<div fxLayout="row" fxLayoutAlign="space-between center" >
  <!-- <h2 mat-dialog-title  style="margin: 0px;">Tarjetas de la vida</h2>
  <button mat-icon-button aria-label="close icon" [mat-dialog-close]="false"  >
      <mat-icon>close</mat-icon>
  </button> -->
</div>

<mat-dialog-content class="mat-typography" fxLayout="column" fxLayoutAlign="center center" [ngStyle.xs]="{'width':'80vw'}" [ngStyle]="{'width':'55vw'}">
    <div fxFlex="55" fxFlex.xs="90" fxLayoutAlign="center center">
        <img style="max-height: 400px; width: 100%;" mat-card-image [src]="currentCard?.cardImage ? currentCard?.cardImage : 'assets/images/game/tarjetas/tarjeta_reverso.png'">
    </div><br>
    <mat-label>{{currentCard?.text}}</mat-label>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button color="primary" [disabled]="currentCard" (click)="selectCard()">Elegir</button>
  <button mat-raised-button color="primary" [disabled]="!currentCard" [mat-dialog-close]="currentCard">Aceptar</button>
  <!-- <button mat-raised-button color="primary" [disabled]="currentCard" [mat-dialog-close]="false">Cancelar</button> -->
</mat-dialog-actions>