// Test requests
// export const SERVER = 'http://scratchya.com.ar/vue/datos.php'; // Return Json
// export const SERVER = 'https://reqres.in/api/users/23'; // Error 404

// URL server
//export const SERVER = 'https://hitecapi20200601195340.azurewebsites.net';
// export const SERVER = 'https://localhost:44334';
export const SERVER = '';

/*
Tipo de datos de retorno:

text | xml | html | script | json
dataType: 'json',

Tipo de datos de envio:

application/json | application/x-www-form-urlencoded |
multipart/form-data | text/plain | charset=utf-8
contentType: 'application/json; charset=utf-8'
*/
export const headers = {
  'Content-Type': 'application/json', // Set value using headers['Content-Type'] = 'newValue';
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
  'Access-Control-Allow-Headers': 'X-Requested-With, content-type, Authorization'
};
