import { AfterViewInit, Component, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { DATA_BILLS } from 'app/views/shared/constants';
import localeEsMx from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { AdminService } from 'app/views/services/admin.service';
import { PaginatorService } from 'app/views/services/paginator.service';
import { HttpClient } from '@angular/common/http';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { API } from 'app/views/shared/api';
import { RoleService } from 'app/views/services/role.service';
registerLocaleData(localeEsMx, 'es-Mx');

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css'],
  providers: [ { provide: LOCALE_ID, useValue: 'es-Mx' } ],
})
export class BillingComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['id','courseName', 'concept', 'payment_state', 'date'];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  arrayPaymentHistory:Array<any> = new Array();
  infoUser:any;

  private headers:any = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'responseType': 'json',
  }

  constructor(
    private service: AdminService,
    private paginatorService: PaginatorService,
    private http: HttpClient,
    private loader: AppLoaderService,
    public role: RoleService
  ) { }

  ngOnInit(): void {
    this.displayedColumns = ['id','courseName', 'concept', 'payment_state', 'date'];
    this.dataSource.data = [];
    this.infoUser = this.service.getUserInfo();
  }

  ngAfterViewInit() {
    this.getPaymentHistory();
    if(this.dataSource.data.length > 0){
      this.paginator._intl = this.paginatorService.translatePaginator(this.paginator._intl);
      this.dataSource.paginator = this.paginator;
      this.arrayPaymentHistory = new Array();
      this.dataSource = new MatTableDataSource<any>(this.arrayPaymentHistory);
    }
  }

  public getPaymentHistory(){
    this.loader.open("Cargando historial de pagos");
    //let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let data = {'user_id': this.infoUser.id};
    let headers_ = {
      ...this.headers,
      'Authorization': `Bearer ${this.infoUser.access_token}`,
    };

    this.http.get(API.URL_BASE+'auth/getPaymentHistory',{headers: headers_, params: data}).toPromise()
    .then((response : any) => {
      this.loader.close();
      if (response.success) {
        this.arrayPaymentHistory = response.data;
        this.arrayPaymentHistory.forEach(
          (ph:any) => {
            let date:string = ph.createdAt;
            ph.createdAt = date.trim().replace(/ /g,"T");
          }
        );
        this.dataSource = new MatTableDataSource<any>(this.arrayPaymentHistory);
        this.dataSource.paginator = this.paginator;
      } else {
        this.service.openDialog("Ha ocurrido un error al obtener el historial","ATENCIÓN");
        this.dataSource = new MatTableDataSource<any>(new Array());
        this.dataSource.paginator = this.paginator;
      }
      this.showTutorial();
    }).catch((error:any)=> {
      this.loader.close();
      this.service.openDialog("Ha ocurrido un error al obtener el historial","ATENCIÓN");
      this.dataSource = new MatTableDataSource<any>(new Array());
      this.dataSource.paginator = this.paginator;
    });
  }

  showTutorial(){
    let tutorialIsDisplayed = localStorage.getItem('tutorialBillingIsDisplayed');
    if(this.role.isClient() && tutorialIsDisplayed!='1'){
      localStorage.setItem('tutorialBillingIsDisplayed','1');
      setTimeout(this.service.showTutorialBillingClient, 100);
    }
  }
}
