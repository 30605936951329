import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatProgressBar } from '@angular/material/progress-bar';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { API } from '../../shared/api';
import { HttpClient } from '@angular/common/http';
import { AppLoaderService } from "app/shared/services/app-loader/app-loader.service";
import { AdminService } from 'app/views/services/admin.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  public userEmail;
  @ViewChild(MatProgressBar) progressBar: MatProgressBar;
  @ViewChild(MatButton) submitButton: MatButton;
  public form: FormGroup;
  public email;

  constructor(
    private router: Router,
    private http: HttpClient,
    private loader: AppLoaderService,
    private adminServ : AdminService
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl(this.email, [
        Validators.required,
        Validators.email,
      ]),
    });
  }

  openConfirm(){
    this.adminServ.openConfirm(`Se enviará un código para restablecer la contraseña. ¿El E-mail "${this.form.controls.email.value}" es correcto?`)
    .then(
      (response) => {
        if (response) {
          this.submitEmail();
        }
      }
    )
    .catch(
      (error) => {
        this.adminServ.openDialog(`Error al enviar código`);
      }
    ); 
  }

  submitEmail() {
    //this.submitButton.disabled = true;
    //this.progressBar.mode = 'indeterminate';
    let data = this.form.value;
    data.email = `${data.email}`.trim();
    this.loader.open();
    this.http.post(API.URL_BASE + "auth/sendPassword",data).toPromise()
    .then((response: any ) => {
      this.loader.close();
      if (response?.exitoso) {
        this.adminServ.openDialog(response?.content).subscribe(
          () => {
            localStorage.removeItem('emailToReset');
            localStorage.setItem('emailToReset', data.email);
            this.router.navigateByUrl("/resetPwd");
          }
        );

      } else {
        this.adminServ.openDialog(response?.content);
      }
    }).catch((error: any )=> {
      this.loader.close();
      this.adminServ.openDialog("Ha ocurrido un error de comunicación");
      
    });
  }

  search(){
    
  }

}
