import { Component, OnInit } from '@angular/core';
import { tr } from 'date-fns/locale';

@Component({
  selector: 'app-test-reglas',
  templateUrl: './test-reglas.component.html',
  styleUrls: ['./test-reglas.component.css']
})
export class TestReglasComponent implements OnInit {
  leekels:any;
  leekelsActuales:any;
  inversion:any;
  gasto:any;
  ahorro:any;
  dia:any;
  totalDias:any = 30;
  contDias:any;
  totalGasto:any;
  submitBool:any;
  cantidadesBool:any;
  materiasPrimas:any;
  deuda:any;
  prestamo:any;
  semana:any;
  submit:any;
  leekelsEstimados:any;

  inversionMin:any;
  gastoMin:any;
  ahorroMin:any;


  options:string[] = ["$100", "$10", "$25", "$250", "$30", "$1000", "$1", "$200", "$45", "$500", "$5", "$20", "Lose", "$1000000", "Lose", "$350", "$5", "$99"];

  startAngle:number = 0;
  spinTimeout: ReturnType<typeof setTimeout> = null;

  spinArcStart:number = 10;
  spinTime:number = 0;
  spinTimeTotal:number = 0;

  spinAngleStart:number = 0;
  arc:number = Math.PI / (this.options.length / 2);
  ctx;


  constructor() { }

  ngOnInit(): void {

    this.drawRouletteWheel();

    this.leekels = 200;
    this.leekelsActuales = this.leekels;

    //Fechas
    this.dia = "Jueves";
    this.contDias = 1;
    this.semana = 1;

    //Variables constantes
    this.inversionMin = 40;
    this.gastoMin = 10;
    this.ahorroMin = 0;

    // Variables del usuario
    this.inversion = 0;
    this.gasto = 0;
    this.ahorro = 0;
    this.deuda = 0;
    this.materiasPrimas = 0;

    this.cantidadesBool = true;
  }




  verificacionLeekels(){
    this.submitBool = false;
    if(this.leekelsActuales < 0){
      this.submitBool = true;
      alert("Tus Leekels están en negativo, ajusta tus gastos!!");
    }
    
    this.totalGasto = this.inversionMin + this.ahorroMin + this.gastoMin;
    this.leekelsActuales = this.leekels - this.totalGasto;
  }

  calendario(){
    switch(this.dia){
      case "Lunes":
        this.contDias++;
        return "Martes";
      case "Martes":
        this.contDias++;
        return "Miercoles";
      case "Miercoles":
        this.contDias++;
        return "Jueves";
      case "Jueves":
        this.contDias++;
        return "Viernes";
      case "Viernes":
        return "Sabado";
      case "Sabado":
        this.contDias++;
        return "Domingo";
      case "Domingo":
        this.contDias++;
        this.semana++;
        return "Lunes";
    }
  }


  avanzarDia(){
    this.dia = this.calendario();
    switch(this.dia){
      case "Lunes":
        this.inversionMin = 40;
        this.gastoMin = 10;
        this.ahorroMin = 0;

        this.leekelsEstimados = this.leekelsActuales;

        this.cantidadesBool = false;
        break;
      case "Martes":
        this.cantidadesBool = true;
        break;
      case "Miercoles":
        this.cantidadesBool = true;
        break;
      case "Jueves":
        this.cantidadesBool = true;
        break;
      case "Viernes":
        this.inversionMin = 40;
        this.gastoMin = 10;
        this.ahorroMin = 0;

        this.leekelsEstimados = this.leekelsActuales;

        this.cantidadesBool = false;
        
        break;
      case "Sabado":
        this.inversionMin = 40;
        this.gastoMin = 10;
        this.ahorroMin = 0;

        this.leekelsEstimados = this.leekelsActuales;

        this.cantidadesBool = false;
        break;
      case "Domingo":
        this.cantidadesBool = true;
        break;

    }
  }

  comprarProducto(precio, producto){
    if((this.gasto - precio) >= 0){
      this.gasto = this.gasto - precio;
    }
    else{
      alert("No puedes comprar este producto, no tienes GASTO suficiente");
    }
  }

  comprarMateriaPrima(precio, producto){
    if((this.inversion - precio) >= 0){
      this.materiasPrimas++;
      this.inversion = this.inversion - precio;
    }
    else{
      alert("No puedes comprar este producto, no tienes INVERSIÓN suficiente");
    }
  }

  venderMateriaPrima(precio, cliente){
    if(this.materiasPrimas > 0){
      this.materiasPrimas--;
      this.ahorro = this.ahorro + precio;
    }
    else{
      alert("No tienes materias primas para vender a "+cliente);
    }
  }

  confirmarCantidades(){
    (<HTMLInputElement> document.getElementById("btnAceptar")).disabled = true;

    this.inversion = this.inversionMin;
    this.gasto = this.gastoMin;
    this.ahorro = this.ahorroMin;

    this.leekels = this.leekelsActuales;

    this.cantidadesBool = true;
  }

  pagarDeuda(){

  }

  solicitarPrestamo(){
    this.deuda = this.deuda + this.prestamo;
  }

  byte2Hex(n) {
    var nybHexString = "0123456789ABCDEF";
    return String(nybHexString.substr((n >> 4) & 0x0F,1)) + nybHexString.substr(n & 0x0F,1);
  }

  RGB2Color(r,g,b) {
    return '#' + this.byte2Hex(r) + this.byte2Hex(g) + this.byte2Hex(b);
  }

  getColor(item, maxitem) {
    var phase = 0;
    var center = 128;
    var width = 127;
    var frequency = Math.PI*2/maxitem;
    
    var red   = Math.sin(frequency*item+2+phase) * width + center;
    let green = Math.sin(frequency*item+0+phase) * width + center;
    let blue  = Math.sin(frequency*item+4+phase) * width + center;
    
    return this.RGB2Color(red, green, blue);
  }

  drawRouletteWheel() {
    var canvas = <HTMLCanvasElement>document.getElementById("canvas");
    if (canvas.getContext) {
      var outsideRadius = 200;
      var textRadius = 160;
      var insideRadius = 125;
  
      this.ctx = canvas.getContext("2d");
      this.ctx.clearRect(0,0,500,500);
  
      this.ctx.strokeStyle = "black";
      this.ctx.lineWidth = 2;
  
      this.ctx.font = 'bold 12px Helvetica, Arial';
  
      for(var i = 0; i < this.options.length; i++) {
        var angle = this.startAngle + i * this.arc;
        //ctx.fillStyle = colors[i];
        this.ctx.fillStyle = this.getColor(i, this.options.length);
  
        this.ctx.beginPath();
        this.ctx.arc(250, 250, outsideRadius, angle, angle + this.arc, false);
        this.ctx.arc(250, 250, insideRadius, angle + this.arc, angle, true);
        this.ctx.stroke();
        this.ctx.fill();
  
        this.ctx.save();
        this.ctx.shadowOffsetX = -1;
        this.ctx.shadowOffsetY = -1;
        this.ctx.shadowBlur    = 0;
        this.ctx.shadowColor   = "rgb(220,220,220)";
        this.ctx.fillStyle = "black";
        this.ctx.translate(250 + Math.cos(angle + this.arc / 2) * textRadius, 
                      250 + Math.sin(angle + this.arc / 2) * textRadius);
                      this.ctx.rotate(angle + this.arc / 2 + Math.PI / 2);
        var text = this.options[i];
        this.ctx.fillText(text, -this.ctx.measureText(text).width / 2, 0);
        this.ctx.restore();
      } 
  
      //Arrow
      this.ctx.fillStyle = "black";
      this.ctx.beginPath();
      this.ctx.moveTo(250 - 4, 250 - (outsideRadius + 5));
      this.ctx.lineTo(250 + 4, 250 - (outsideRadius + 5));
      this.ctx.lineTo(250 + 4, 250 - (outsideRadius - 5));
      this.ctx.lineTo(250 + 9, 250 - (outsideRadius - 5));
      this.ctx.lineTo(250 + 0, 250 - (outsideRadius - 13));
      this.ctx.lineTo(250 - 9, 250 - (outsideRadius - 5));
      this.ctx.lineTo(250 - 4, 250 - (outsideRadius - 5));
      this.ctx.lineTo(250 - 4, 250 - (outsideRadius + 5));
      this.ctx.fill();
    }
  }

  spin() {
    this.spinAngleStart = Math.random() * 10 + 10;
    this.spinTime = 0;
    this.spinTimeTotal = Math.random() * 3 + 4 * 1000;
    this.rotateWheel();
  }

  rotateWheel() {
    this.spinTime = this.spinTime + 30;
    if(this.spinTime >= this.spinTimeTotal) {
      this.stopRotateWheel();
      return;
    }
    var spinAngle = this.spinAngleStart - this.easeOut(this.spinTime, 0, this.spinAngleStart, this.spinTimeTotal);
    this.startAngle += (spinAngle * Math.PI / 180);
    this.drawRouletteWheel();
    this.spinTimeout = setTimeout(() => {this.rotateWheel()}, 30);
  }

  stopRotateWheel() {
    clearTimeout(this.spinTimeout);
    var degrees = this.startAngle * 180 / Math.PI + 90;
    var arcd = this.arc * 180 / Math.PI;
    var index = Math.floor((360 - degrees % 360) / arcd);
    this.ctx.save();
    this.ctx.font = 'bold 30px Helvetica, Arial';
    var text = this.options[index]
    this.ctx.fillText(text, 250 - this.ctx.measureText(text).width / 2, 250 + 10);
    this.ctx.restore();
  }

  easeOut(t, b, c, d) {
    var ts = (t/=d)*t;
    var tc = ts*t;
    return b+c*(tc + -3*ts + 3*t);
  }

  callRotateWheel(){
  }
  
}
