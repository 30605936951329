import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressBar } from '@angular/material/progress-bar';
import { ActivatedRoute } from '@angular/router';
import { egretAnimations } from 'app/shared/animations/egret-animations';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { DialogConfirmComponent } from 'app/views/dialogs/dialog-confirm/dialog-confirm.component';
import { AdminService } from 'app/views/services/admin.service';
import { CustomValidators } from 'ngx-custom-validators';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
  animations: [egretAnimations]
})
export class SignupComponent implements OnInit {

  @ViewChild(MatProgressBar) progressBar: MatProgressBar;
  form: FormGroup;
  hide = true;
  hideConfirm = true;

  constructor(
    private dialog: MatDialog,
    private service: AdminService,
    private loader: AppLoaderService,
    private activatedRoute:ActivatedRoute
  ) { }

  ngOnInit(): void {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
    this.createForm();
    this.activatedRoute.queryParams.subscribe(
      (params) => {
        if (params) {
          if (
            params.hasOwnProperty('name') &&
            params.hasOwnProperty('lastname') &&
            params.hasOwnProperty('email') 
          ) {
            this.form.controls['name'].setValue(params.name);
            this.form.controls['lastName'].setValue(params.lastname);
            this.form.controls['email'].setValue(params.email);
            this.service.openSnackBar('Continua tu registro en esta sección por favor.')
          }
        }
      }
    )
  }
  
  public matchEmail:boolean = true;

  createForm(){
    const password = new FormControl('', [
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(20)
    ]);
    const regex = "[a-zA-Z-ñÑáéíóúÁÉÍÓÚ ,.$&%_]*$";
    this.form = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
        Validators.pattern(regex)
      ]),
      lastName: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
        Validators.pattern(regex)
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.minLength(5),
        Validators.maxLength(255)
      ]),
      confirm_email: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.minLength(5),
        Validators.maxLength(255)
      ]),
      password: password,
      confirmPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(20),
        CustomValidators.equalTo(password)
      ]),
    });
  }

  search(){
    
  }

  showConfirmDialog(){
    this.dialog.open(DialogConfirmComponent, {
      data: {
        message: '¿Seguro(a) que desea crear la cuenta?'
      }
    }).afterClosed().subscribe((response) => {
      if (response == true){
        this.signup();
      } else {
        this.service.openSnackBar('No se creó la cuenta');
      }
    });
  }

  signup(){
    if (this.form.valid){
      this.loader.open('Creando cuenta');
      const loader = this.loader.getDialog();
      this.service.signup(this.form.value).subscribe(
        (data:any) => {
          loader.close();
          //console.log(data);
          this.service.openDialog(data.message);
          this.service.navigateTo('/signin');
        },
        (http: HttpErrorResponse) => {
          loader.close();
          //console.log(http);
          switch(http.status) {
            case 422:
              this.service.openDialog('El Correo Electrónico ya está en uso, ingrese otro por favor.');
              break;
            default:
              this.service.openDialog('No fue posible crear la cuenta, error en el servicio');
          }
        }
      );
    } else {
      this.service.openDialog('Complete los campos correctamente');
    }
  }

  // Funcion para confirmacion de correo
  public onEmailChange(){


    if (this.form.get('email').value != this.form.get('confirm_email').value){
      this.matchEmail = false;
    } else {
      this.matchEmail = true;
    }
  }

}
