import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ComunicationService } from 'app/comunication.service';
import { DialogPromotionUserComponent } from 'app/shared/components/dialog-promotion-user/dialog-promotion-user.component';
import { DialogPromotionComponent } from 'app/shared/components/dialog-promotion/dialog-promotion.component';
import { DialogComponent } from 'app/shared/components/dialog/dialog.component';
import { BaseService, IResponse } from 'app/views/services/base.service';
import { PaginatorService } from 'app/views/services/paginator.service';
import { RoleService } from 'app/views/services/role.service';

@Component({
  selector: 'app-admin-promotions',
  templateUrl: './admin-promotions.component.html',
  styleUrls: ['./admin-promotions.component.css']
})
export class AdminPromotionsComponent extends BaseService implements OnInit {
  
  row:any;
  rolVer:boolean;
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public roleService:RoleService,
    public notificationC:ComunicationService,
    injector: Injector
  ) { 
    super(injector);
  }

  tope=0;
  intervalo;
  async mensaje() {
    await this.notificationC.setNotifications();
    this.tope++;
  }
  intervalo_(notificationC_) {
  this.intervalo= setInterval(() => { this.mensaje(); },600000);
  }

  ngOnInit(): void {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let isLogged = (userInfo != null && userInfo != undefined);
    if (isLogged){
      this.notificationC.setNotifications();
      //this.intervalo_(this.notificationC);
    }
    this.rolVer = !this.roleService.isInstructor();
    this.displayedColumns = ['promotion', 'description','tipo', 'valor', 'labelAllCoursesApply','vigencia','delete','edit','Users'];
    this.getCatPromotions();
  }

  getCatPromotions(){
      this.loader.open('Cargando listado ...');
      this.service.getCatPromotions().subscribe(
        (data:any) => {
          switch(data.status){
            case 200:
              this.dataSource.data =  data.body.content;
              this.loader.close();
              break;
            default:
              this.loader.close();
              this.service.openDialog("No se encontraron datos");
              break;
          }
          
        },
        (http: HttpErrorResponse) => {
          this.loader.close();
          this.service.openDialog("Error en el Servicio 1204");
        }
      );
  }

  ngAfterViewInit() {
    this.paginator._intl = this.paginatorService.translatePaginator(this.paginator._intl);
    this.dataSource.paginator = this.paginator;
  }
  
  public openDialogEdit() {  
    this.dialog.open(DialogPromotionComponent, {
      disableClose: true,
      width: '50%',
      data:this.row
    })
    .afterClosed()
    .subscribe((data: any) => {
        if (data != null) {
          this.updateCatPromotion(data);
        } 
      });
  }

  public openDialogAssignment() {
   this.dialog.open(DialogPromotionUserComponent, {
      disableClose: true,
      width: '50%',
      data:this.row
    })
    .afterClosed()
    .subscribe((data: any) => {
        if (data != null && data.list.length > 0) {
          this.savePromoTionUsers(data);
        } 
      });
  }

  deleteCatPromotion(data:any){
    this.loader.open();
    this.service.deleteCatPromotion(data).subscribe(
      (response:any) => {
        this.loader.close();
        let content:any = response.content;
        this.service.openDialog(content.message).subscribe(
          () =>{ 
            if (content.affected > 0) {
              this.getCatPromotions();
            }  
          }
        );
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        let msg = http.error.content;
        if(http.error.content.toString().substring(0, 15) == 'SQLSTATE[23000]')
          msg = 'No puede eliminar una promoción que esté asignada a uno/varios usuarios o cursos.';

        this.service.openDialog(msg);
      }
    );
  }

  updateCatPromotion(data){    
    this.loader.open();
    this.service.updateCatPromotion(data).subscribe(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        this.openDialog(`${body.message}`).subscribe(
          () => {
            if (body.success) this.getCatPromotions();
          }
        );        
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(http);
      }
    );
  }

  savePromoTionUsers(data){    
    this.loader.open('Guardando Alumnos ...');
    this.service.savePromocionUser(data).subscribe(
      (data:any) => {
        this.loader.close();
        this.getCatPromotions();
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.processHttpResponse(http);
      }
    );
  }

  saveCatPromotion(data){
    this.loader.open();
    this.service.saveCatPromocion(data).subscribe(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        this.openDialog(`${body.message}`).subscribe(
          () => {
            if (body.success) this.getCatPromotions();
          }
        );        
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(http);
      }
    );
  }

  onClickEdit(item:any){
    this.row =item;
    this.openDialogEdit();
  }

  public onClickNuevo() {
    let item ={
      id:0,
      description:"",
      promotion:"",
      vigencia:"",
      allCoursesApply:0,
      valor:0,
      tipo:0,
      promotionTypeId:0,

    }
    this.dialog.open(DialogPromotionComponent, {
      disableClose: true,
      width: '50%',
      data:item,
    })
    .afterClosed()
    .subscribe((data: any) => {
        if (data != null) {
          this.saveCatPromotion(data);
        } 
      });
  }

  onClickDelete(item:any,checkbox:any,index:number){
    checkbox._checked = (checkbox._checked)?true:false;
    let message =item.active==1?"desactivar":"activar";
    this.dialog.open(DialogComponent, {
      disableClose: true,
      data: {
        title: `Confirmación:`,
        content: `Seguro(a) que desea ${message} la promoción`,
        viewButton:true
      }
    })
    .afterClosed()
    .subscribe(
      (response: Boolean) => {
        if (response) {
          let data:any = item;
          data.active = (checkbox._checked)?1:0;
          this.deleteCatPromotion(data);
        } else {
          checkbox._checked = (checkbox._checked)?false:true;
        }
      });
  }

  onClickAssignment(item:any){
    this.row =item;
    this.openDialogAssignment();
  }

}
