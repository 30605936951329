import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ROULETTE } from 'app/views/dashboard/game/game.component';

@Component({
  selector: 'app-sam-roulette',
  templateUrl: './sam-roulette.component.html',
  styleUrls: ['./sam-roulette.component.css']
})
export class SamRouletteComponent implements OnInit {
  public roulette:any;
  public disableRouletteButton:boolean;
  constructor(private dialogRef: MatDialogRef<SamRouletteComponent>) { }

  ngOnInit(): void {
  }

  spinRoulette(){
    this.roulette = ROULETTE[1];
    this.disableRouletteButton = true;
    setTimeout(() => {
      let index:number = this.getRandomNumber(2,8);
      this.roulette = ROULETTE[index];
      this.disableRouletteButton = false;
      // this.dialogRef.close({ data: this.roulette });
    },7000);
  }

  getRandomNumber(min:number,max:number):number{
    return Math.floor(Math.random() * (max - min) + min);
  }

}
