

<button type="button" (click)="spin()">dfdfdf</button>
<canvas #canvas id="canvas" width="500" height="500"></canvas>
<!-- MANEJO DE CANTIDADES -->
<div>
    <h2>Día actual: {{ this.dia }} || Semana: {{ this.semana }} || Días Transcurridos: {{ this.contDias }}</h2><br>
    <h3>Leekels actuales: {{ this.leekels }} </h3>
    <div style="border: 1px solid black; width: 20%;">
        <h4>Inversión: {{ this.inversion }}</h4>
        <h4>Gasto: {{ this.gasto }}</h4>
        <h4>Ahorro: {{ this.ahorro }}</h4>
        <h4>Deuda: {{ this.deuda }}</h4>
        <h4>Materias Primas: {{ this.materiasPrimas }}</h4>
    </div>
    <button (click)="avanzarDia()">AVANZAR DÍA</button>
</div>

<!-- TIENDA DE LUIS -->
<div *ngIf = "this.dia == 'Lunes'">
    <h4>Tienda de Luis</h4>
    <div class="container">
        <div class="row">
            <div class="col-sm" style="border: 1px solid black; text-align: center;">
                <label>Materia Prima</label><br>
                <label>Precio: 40</label><br>
                <button (click)="comprarMateriaPrima(40, 'Materia Prima')">Comprar</button><br><br>
            </div>
            <div class="col-sm" style="border: 1px solid black; text-align: center;">
                <label>Devoluciones</label><br>
                <label>Precio: 20</label><br>
                <button (click)="comprarMateriaPrima(20, 'Devoluciones')">Comprar</button><br><br>
            </div>
        </div>
    </div>
</div>

<!-- PRODUCTO -->
<div *ngIf = "this.dia == 'Martes'">
    <h4>Producto</h4>
    <img src="https://media.timeout.com/images/105560138/630/472/image.jpg" style="width: 30%; height: 20%;">
</div>

<!-- MENSAJE "DISPONIBLE EN LA TERCERA SEMANA" -->
<div *ngIf = "this.dia == 'Miercoles' && this.semana < 3">
    <h4>Esta opción estará disponible en la tercera semana</h4>
</div>

<!-- VENTA MARIA Y SAM -->
<div *ngIf = "this.dia == 'Miercoles' && this.semana >= 3">
    <h4>Tienda de Maria y Sam</h4>
    <div class="container">
        <div class="row">
            <div class="col-sm" style="border: 1px solid black; text-align: center;">
                <label>Vender a Maria</label><br>
                <label>Precio: 70</label><br>
                <button (click)="venderMateriaPrima(70, 'Maria')">Vender</button><br><br>
            </div>
            <div class="col-sm" style="border: 1px solid black; text-align: center;">
                <label>Vender a Sam</label><br>
                <label>Precio: 90</label><br>
                <button (click)="venderMateriaPrima(90, 'Sam')">Vender</button><br><br>
            </div>
        </div>
    </div>
</div>

<!-- COBROS Y PAGOS -->
<div *ngIf = "this.dia == 'Jueves' && this.contDias > 1">
    <h4>Cobros y Pagos</h4>
    <div class="container">
        <div class="row">
            <div class="col-sm" style="border: 1px solid black; text-align: center;">
                <label>Pagar Deuda</label><br>
                <label>Deuda: {{ this.deuda }}</label><br>
                <button (click)="pagarDeuda()">Pagar</button><br><br>
            </div>
            <div class="col-sm" style="border: 1px solid black; text-align: center;">
                <label>Solicitar Prestamo</label><br>
                <input [(ngModel)]="prestamo" name="prestamo" type="number" min="0" placeholder="0"><br>
                <button (click)="solicitarPrestamo()">Solicitar</button><br><br>
            </div>
        </div>
    </div>
</div>

<!-- Planeación Financiera -->
<div *ngIf = "this.dia == 'Viernes' || this.dia == 'Sabado' || this.dia == 'Lunes'">
    <h4>Planeación Financiera</h4>
    <h4>Presupuesto: {{ this.leekelsActuales }}</h4>
    <label>Inversión: </label><input [(ngModel)]="inversionMin" disabled="{{ this.cantidadesBool }}" (ngModelChange)="verificacionLeekels()" name="inversionMin" type="number" min="40" placeholder="40"><br>
    <label>Gasto: </label><input [(ngModel)]="gastoMin" disabled="{{ this.cantidadesBool }}" (ngModelChange)="verificacionLeekels()" name="gastoMin" type="number" min="10" placeholder="10"><br>
    <label>Ahorro: </label><input [(ngModel)]="ahorroMin" disabled="{{ this.cantidadesBool }}" (ngModelChange)="verificacionLeekels()" name="ahorroMin" type="number" min="0" placeholder="0"><br>
    <button id="btnAceptar" (click)="confirmarCantidades()" >ACEPTAR</button><br>
</div>

<!-- TIENDA DE CARLOS -->
<div *ngIf = "this.dia == 'Sabado'">
    <h4>Tienda de Carlos</h4>
    <div class="container">
        <div class="row">
            <div class="col-sm" style="border: 1px solid black; text-align: center;">
                <label>Producto 1</label><br>
                <label>Precio: 30</label><br>
                <button (click)="comprarProducto(30, 'Producto 1')">Comprar</button><br><br>
            </div>
            <div class="col-sm" style="border: 1px solid black; text-align: center;">
                <label>Producto 2</label><br>
                <label>Precio: 40</label><br>
                <button (click)="comprarProducto(40, 'Producto 2')">Comprar</button><br><br>
            </div>
            <div class="col-sm" style="border: 1px solid black; text-align: center;">
                <label>Producto 3</label><br>
                <label>Precio: 50</label><br>
                <button (click)="comprarProducto(50, 'Producto 3')">Comprar</button><br><br>
            </div>
        </div>
        <div class="row">
            <div class="col-sm" style="border: 1px solid black; text-align: center;">
                <label>Producto 4</label><br>
                <label>Precio: 60</label><br>
                <button (click)="comprarProducto(60, 'Producto 4')">Comprar</button><br><br>
            </div>
            <div class="col-sm" style="border: 1px solid black; text-align: center;">
                <label>Producto 5</label><br>
                <label>Precio: 70</label><br>
                <button (click)="comprarProducto(70, 'Producto 5')">Comprar</button><br><br>
            </div>
            <div class="col-sm" style="border: 1px solid black; text-align: center;">
                <label>Producto 6</label><br>
                <label>Precio: 80</label><br>
                <button (click)="comprarProducto(80, 'Producto 6')">Comprar</button><br><br>
            </div>
        </div>
        <div class="row">
            <div class="col-sm" style="border: 1px solid black; text-align: center;">
                <label>Producto 7</label><br>
                <label>Precio: 90</label><br>
                <button (click)="comprarProducto(90, 'Producto 7')">Comprar</button><br><br>
            </div>
            <div class="col-sm" style="border: 1px solid black; text-align: center;">
                <label>Producto 8</label><br>
                <label>Precio: 100</label><br>
                <button (click)="comprarProducto(100, 'Producto 8')">Comprar</button><br><br>
            </div>
            <div class="col-sm" style="border: 1px solid black; text-align: center;">
                <label>Producto 9</label><br>
                <label>Precio: 110</label><br>
                <button (click)="comprarProducto(110, 'Producto 9')">Comprar</button><br><br>
            </div>
        </div>
    </div>
</div>

<!-- DESCANSO -->
<div *ngIf = "this.dia == 'Domingo'">
    <h4>DESCANSO</h4>
</div>