<app-header-top-sessions *ngIf="isLogged == false"></app-header-top-sessions>
<app-header-top *ngIf="isLogged"></app-header-top>
<div
  [ngStyle]="isLogged ? {'margin-top':'10em'} : {}"
  fxFlex="100"
  fxFlex.xs="98"
  fxLayout="column"
  >
  <div
    fxFlex="100"
    fxLayout="column"
    fxLayout.sm="column"
    fxLayout.xs="column"
    >
    <div
      style="width: 100%;"
      [style]="(isLogged)?'':'margin-top: 9em;'"
      >
      <!--Start Meet Teachers One-->
      <section class="meet-teachers-one meet-teachers-one--teachers">
        <div class="container">
            <div class="row">
                <h2 class="teaching-one__content-title" style="text-align:center; padding-bottom:20px;">AVISO DE PRIVACIDAD</h2>
                <!--Start Single Meet Teachers One-->
                <div class="col-xl-12 col-lg-12">
                    <div class="meet-teachers-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">

                        <div class="meet-teachers-one__single-content">
                            <div class="meet-teachers-one__single-middle-content">
                                <p class="meet-teachers-one__single-content-text" style="text-align:justify !important;">De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de Particulares, Q22 GLOBAL SA DE CV pone a disposición y conocimiento de los interesados el siguiente Aviso de Privacidad.<br><br> 

                                  Q22 GLOBAL SA DE CV y FUNDACIÓN EDUCACIÓN PARA EMPRENDEDORES AC son los responsables del uso, resguardo y protección de los datos personales que usted nos proporcione; en este sentido y de conformidad con las obligaciones estipuladas en la Ley Federal de Protección de Datos Personales en Posesión de Particulares el presente Aviso establece la información recabada, así como el uso y fines que se darán a dicha información. Q22 GLOBAL SA DE CV y FUNDACIÓN EDUCACIÓN PARA EMPRENDEDORES AC tienen su domicilio en Av Paseo de la Reforma No. 509, Piso 16, Cuauhtémoc, cp 065000 Ciudad de México.
                                  Ambas empresas recaban datos personales para las siguientes finalidades:
                                  <br><br> 

                                    &nbsp;&nbsp;&nbsp;&nbsp;- Estudio y generación de estadísticas.<br> 
                                    &nbsp;&nbsp;&nbsp;&nbsp;- Promoción y difusión de los programas que desarrollamos<br> 
                                    &nbsp;&nbsp;&nbsp;&nbsp;- Comprobación de recursos y seguimiento de resultados<br> 
                                    &nbsp;&nbsp;&nbsp;&nbsp;- Elaboración de cotizaciones<br> 
                                    &nbsp;&nbsp;&nbsp;&nbsp;- Evaluación de calidad<br><br>  
                                    
                                    Los datos personales que recabamos de usted para las finalidades aquí descritas son aquellos que usted nos proporciona cuando visita nuestra página de internet o nuestras oficinas, cuando toma algún curso o taller proporcionado por nosotros, así como cuando participa en algún programa desarrollado u operado por nosotros; así como aquella que obtenemos por fuentes públicas permitidas por la ley.<br><br>  
                                    
                                    De conformidad con la Ley Federal de Protección de Datos Personales en Posesión de Particulares, usted tiene en todo momento el derecho a conocer el uso que se le da a sus datos personales, así como a solicitar la corrección de los datos proporcionado, solicitar la cancelación del uso de sus datos y a oponerse al uso de sus datos personales para fines específicos. Lo anterior constituye los derechos ARCO estipulados en la Ley Federal de Protección de Datos Personales en Posesión de Particulares y para poder ejercerlos usted puede enviar en cualquier momento un correo electrónico a la dirección: karla@fundacione.org en atención a la Lic. Karla Monserrat Martínez Silva, quien será responsable de dar trámite a su petición, la cual será contestada en un plazo no mayor a los 30 días hábiles.<br><br>   
                                    
                                    Usted puede revocar el consentimiento otorgado para el trato de sus datos personales en cualquier momento a través de los formatos que se ponen a su disposición en la página www.my4x4academy.com Cabe mencionar que la revocación del consentimiento para el uso de datos personales puede implicar la conclusión de su relación con nosotros o que no podamos brindarle algún servicio o pueda participar en nuestros programas.<br> <br> 
                                    
                                    Para conocer los cambios a nuestro modelo de tratamiento de datos o la actualización de nuestro aviso de privacidad, consulte la página de la organización.<br>  
                                    </p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <!--End Single Meet Teachers One-->
              </div>
          </div>
      </section>
      <!--End Meet Teachers One-->
      <app-footer *ngIf="isLogged"></app-footer>
    </div>
    <div fxFlex="100">
      <app-footer *ngIf="!isLogged"></app-footer>  
    </div>
  </div>
</div>