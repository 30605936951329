<app-header-top-sessions *ngIf="isLogged == false"></app-header-top-sessions>
<app-header-top *ngIf="isLogged"></app-header-top>
<div
  [ngStyle]="isLogged ? {'margin-top':'10em'} : {}"
  fxFlex="100"
  fxFlex.xs="98"
  fxLayout="column">
  <div
    fxFlex="100"
    fxLayout="column"
    fxLayout.sm="column"
    fxLayout.xs="column"
    >
    <div
      style="width: 100%;"
      [style]="(isLogged)?'height: 100vh;':'margin-top: 9em;'"
      >
      <!--Start Meet Teachers One-->
      <section class="meet-teachers-one meet-teachers-one--teachers">
        <div class="container">
            <div class="row">
                <h2 class="teaching-one__content-title" style="text-align:center; padding-bottom:20px;">Términos y condiciones de uso de la Plataforma digital My 4x4 Academy</h2>
                <!--Start Single Meet Teachers One-->
                <div class="col-xl-12 col-lg-12">
                    <div class="meet-teachers-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">

                        <div class="meet-teachers-one__single-content">
                            <div class="meet-teachers-one__single-middle-content">
                              <p>
                                Antes de comenzar a utilizar y navegar en la plataforma, le sugerimos lea con atención los siguientes términos y condiciones, así como la política de privacidad de la Plataforma.
                              </p>
                              <p>
                                <b>1.- Propósito y objeto de la Plataforma My 4x4 Academy</b>
                                <br/><br/>
                                La plataforma digital  My 4 x4 Academy es un sistema de aprendizaje interactivo digital especializada en el desarrollo de cultura emprendedora. Consiste en un sistema de aprendizaje híbrido, utilizando plataformas asincrónicas y sincrónicas de manera simultánea.
                                <br/><br/>
                                El aprendizaje se encuentra basado en retos y objetivos a través de los cuales, el usuario podrá desarrollar micro competencias alineadas a la realidad del mercado actual y cambiante, en un sistema de auto aprendizaje en línea y acompañamientos por asesores a través del sistema digital con base al calendario.
                              </p>
                              <!---->
                              <p>
                                <b>2.- Cuotas y membresía.</b>
                                <br/><br/>
                                La plataforma My 4 x4 Academy, contiene una oferta de cursos y talleres cuyo precio varía de acuerdo al momento y el curso o taller que se elija. Los precios se encontrarán publicados en la página de inicio de la plataforma y serán actualizados en el mismo medio, por lo que el usuario deberá verificar previamente los precios y costos publicados en la página. 
                              </p>
                              
                              <p>
                                <b>3.- Acceso</b>
                                <br/><br/>
                                Una vez realizado el pago correspondiente al curso seleccionado por el usuario, se le designará un facilitador para los eventos sincrónicos y consultas en su calendario personalizado; el cual tendrá las funciones de dirigir al usuario en la consecución de los objetivos, así como apoyo en las actividades y retos correspondientes a cada curso.  
                                Para que el usuario pueda ingresar a las herramientas de cada curso, contará con una clave, y para el acceso a las actividades sincrónicas recibirá por parte del facilitador una clave específica para la actividad. la cual será previamente asignada por “el facilitador”. Las actividades asincrónicas el participante las podrá llevar a cabo en los tiempos que mejor considere.
                              </p>
                        
                              <p>
                                <b>4.- Uso</b>
                                <br/><br/>
                                Mientras se encuentre en uso de la Plataforma, el usuario se compromete a: 
                              </p>
                              <ul style="text-align: left;">
                                a)	Cumplir con todas las disposiciones aquí establecidas<br/>
                                b)  No publicar, compartir ni subir información ilícita o engañosa<br/>
                                c)	No infringir los derechos de propiedad intelectual o derechos de autor de la misma Plataforma, de los demás participantes de la Plataforma o de algún tercero<br/>
                                d)	No distribuir ni enviar por ningún medio de contacto SPAM.<br/>
                                e)	Mantener normas y conductas de respeto a ideas, puntos de vista y opiniones de todos quienes participan en la plataforma.<br/>
                                f)	Salvaguardar la integridad de las personas que participan asi como la confidencialidad de los proyectos.<br/><br/>
                                <b>4.1.</b> El usuario podrá comunicarse con los demás participantes a través de los medios disponibles. Previo acuerdo con el facilitador y bajo autorización de los participantes.
                              </ul>
                              <br/>
                              <p>
                                <b>5. Propiedad intelectual </b>
                                <br/><br/>
                                Todo el material que forma parte de los cursos y talleres disponibles en la Plataforma es propiedad intelectual de My 4x4 Academy, el cual se encuentra registrado y patentando ante las instancias internacionales de protección a los derechos de autor.
                                <br/><br/>
                                Así mismo, las marcas, denominaciones, logotipos así como cualquier símbolo y trabajo protegido pertenece únicamente a My4x4Academy por lo que está prohibida su reproducción total o parcial, traducción, inclusión, transmisión, almacenamiento o acceso a través de medios análogos, digitales o de cualquier otro sistema o tecnología, sin autorización previa por escrito de My4x4Academy 
                              </p>
                                
                              <p>
                                <b>6. Causas de suspensión de uso y responsabilidad</b>
                                <br/><br/>
                                En caso de que el usuario incumpliera con las disposiciones establecidas en el numeral 4 de los presentes términos y condiciones, My4x4Academy podrá suspender el acceso al usuario sin previa notificación.
                                <br/><br/>
                                La suspensión también podrá aplicarse si el usuario hiciera uso indebido de la plataforma entre otras; comentarios difamatorios, insultos o agresiones dentro de la comunidad. En cuyo caso My4x4Academy se reserva el derecho de cancelar la suscripción sin derecho a devolución del pago correspondiente.
                                <br/><br/>
                                El usuario es responsable de la información personal que comparta y se compromete a respetar la privacidad de los demás usuarios. 
                                <br/><br/>
                                El servicio de la Plataforma es ofrecer, en la medida de sus posibilidades, un servicio disponible y responder ante cualquier contingencia tecnológica. El usuario acepta que pueden presentarse factores fuera del control de My4x4Academy, incluidos, entre otros, el funcionamiento, la ineficacia o inadecuación del equipo y la falta de disponibilidad, funcionamiento o interrupción de internet u otros servicios de telecomunicación. La falta de cumplimiento por parte de My4x4Academy no se considerará infracción cuando dicho incumplimiento resulte directa o indirectamente de un caso de interrupción del servicio o conectividad
                              </p>
                        
                              <p>
                                <b>7. Datos personales</b>
                                <br/><br/>
                                Los datos personales que los usuarios ingresan al momento de acceder para usar la Plataforma serán tratados de conformidad con la Ley General de Protección de Datos Personales en Posesión de Sujetos Obligados de la República Mexicana.
                                <br/><br/>
                                En este sentido, el usuario es consciente de que sus datos personales recabados al momento de suscribirse a la Plataforma forman parte de la Base de Datos general de la Plataforma My4x4Academy y son usados única y exclusivamente para acceso, control y seguimiento de las actividades realizadas en la Plataforma. Para oponer cualquier derecho de Acceso, Rectificación, Cancelación u Oposición de sus datos personales, el usuario podrá escribir a contacto@fundacione.org, encargado del tratamiento de datos personales. 
                              </p>
                        
                              <p>
                                <b>8. Vigencia y modificaciones</b>
                                <br/><br/>
                                Los términos y condiciones descritos en el presente documento entrarán en vigor a partir de la fecha en que el usuario los acepte y tendrán una duración ilimitada. 
                                <br/><br/>
                                La vigencia de la membresía del usuario será de 3 meses a partir del pago de la inscripción, tiempo en el que el participante podrá realizar el taller. Una vez concluido este período, el usuario podrá solicitar una prórroga de 30 días naturales por un costo de $50 USD. 
                                <br/><br/>
                                Una vez transcurrido el plazo de 30 días si el curso no se ha concluido, la membresía será desactivada y el usuario tendrá que pagar una nueva inscripción para poder concluirlo o iniciarlo nuevamente. 
                                <br/><br/>
                                La plataforma puede rescindir este contrato en los términos que establecen los numerales 4 y 5 que anteceden. 
                                <br/><br/>
                                My4x4Academy  se reserva el derecho de modificar estos términos y condiciones en forma discrecional en cualquier momento. En tal caso se dará aviso por correo electrónico al usuario de manera oportuna. Si el usuario no presenta objeciones en un plazo de dos semanas se presumirá que los nuevos términos y condiciones han sido aceptados. 
                                <br/><br/>
                                En caso de que alguna disposición de este contrato, o alguna disposición incorporada en el futuro al presente contrato sea o resulte no válida o inaplicable, o en caso de que este contrato contenga una omisión, la validez o aplicabilidad de las demás disposiciones de este contrato no se verán, por lo tanto, afectadas. 
                              </p>

                              <p>
                                <b>9. Garantía de satisfacción</b>
                                <br/><br/>
                                A partir del momento en que un participante se inscribe y su pago es autorizado, cuenta con un total de 5 días naturales para determinar su satisfacción con los contenidos, metodología y en general tecnología de la plataforma My 4x4 Academy, mientras se encuentre en este período de tiempo el participante puede solicitar la devolución de su pago sin otro motivo más que la petición del mismo; una vez transcurridos los 5 días hábiles el participante es responsable de tomar el curso o taller que haya seleccionado con base a al numeral 8 de estos términos y condiciones, sin derecho a solicitar reembolso e independientemente a los términos y condiciones de la plataforma de pago.
                              </p>

                              <p>
                                <b>10. Lectura y entendimiento</b>
                                <br/><br/>
                                Al momento de realizar un pago en la plataforma y este haya sido aprobado, el participante o usuario acepta haber leído, comprendido y estar de acuerdo con los términos y condiciones de My 4x4 Academy
                              </p>

                            </div>
                          </div>
                      </div>
                  </div>
                  <!--End Single Meet Teachers One-->
              </div>
          </div>
      </section>
      <!--End Meet Teachers One-->
      <app-footer *ngIf="isLogged"></app-footer>
    </div>
    <div fxFlex="100">
      <app-footer *ngIf="!isLogged"></app-footer>
    </div>
  </div>
</div>