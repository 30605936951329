import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseService, ICourse, IRelCoursesPaymentsMethods, IResponse, IStripePrice, IStripePriceType } from 'app/views/services/base.service';

@Component({
  selector: 'app-dialog-manage-stripe-prices',
  templateUrl: './dialog-manage-stripe-prices.component.html',
  styleUrls: ['./dialog-manage-stripe-prices.component.css']
})
export class DialogManageStripePricesComponent extends BaseService implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogManageStripePricesComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    injector: Injector
  ) {
    super(injector);
  }

  currencies:any[] = [];
  form:FormGroup;
  priceTypes:IStripePriceType[] = [];
  coursePrices:IStripePrice[] = [];
  course:ICourse;
  rcpm:IRelCoursesPaymentsMethods;
  ngOnInit(): void {
    try {
      this.createForm();
      this.course = this.data.course;
      this.rcpm   = this.data.rcpm;
      this.setFormValues();
      this.currencies.push({id:1,currency:'usd',currencyName:'Dólar estadounidense'});
      this.getCoursePrices();
    } catch (error) {
      this.openDialog(`Ocurrió un error al iniciar ventana de precios: ${error.message}`);
    }
  }

  getCoursePrices(): void {
    let data:any = {
      courseId:this.course.courseId,
      page:0,
      pageSize:50,
      productId:this.course.productId
    };
    this.loader.open();
    this.service.getCoursePrices(data).subscribe(
      (response) => {
        this.loader.close();
        const body:IResponse = response.body;
        this.priceTypes = body.data.priceTypes;
        if (this.priceTypes.length <= 0) {
          this.service.openDialog(`No hay tipos de precio para elegir. Favor de verificar.`).subscribe(
            () => {this.close();}
          );
        } else {
          this.coursePrices = body.data.coursePrices.data;
        }
      },
      (error:HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(error);
      }
    );
  }

  close(): void {
    this.dialogRef.close();
  }

  setFormValues(): void {
    let control:AbstractControl = this.form.controls.productId;
    control.setValue(this.course.productId);
    control.disable();
    control = this.form.controls.name;
    control.setValue(this.course.course);
    control.disable();
    control = this.form.controls.courseId;
    control.setValue(this.course.courseId);
    control.disable();
    control = this.form.controls.unitAmount;
    control.disable();
  }

  currency:string;
  setCurrency(currency): void {
    if (!this.priceTypeId) {
      this.form.controls['priceTypeId'].markAsTouched();
      return;
    }
    if (this.priceTypeId == 1) {
      this.form.controls.unitAmount.enable();
    }
    this.currency = currency;
  }

  priceTypeId:number;
  setPriceTypeId(priceTypeId:number,amount:number = 0): void {
    this.form.controls.priceTypeId.setValue(priceTypeId);
    this.priceTypeId = priceTypeId;
    
    let formControl:FormControl;
    let control:AbstractControl;
    if (priceTypeId == 2) {
      formControl = new FormControl('',[
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(4)
      ]);
      control = this.form.controls.unitAmount;
      control.disable();
      control.setValue(this.course.salePrice);
      control = this.form.controls.promotionAmount;
      control.enable();
      control.setValue('');
    } else {
      formControl = new FormControl({value:'',disabled:true},[]);
      control = this.form.controls.promotionAmount;
      control.disable();
      control.setValue('');
      control = this.form.controls.unitAmount;
      control.enable();
      control.setValue('');
    }
    this.form.setControl('promotionAmount',formControl);
  }

  createForm(): void {
    this.form = new FormGroup({
      productId:new FormControl('',[
        Validators.required
      ]),
      courseId:new FormControl('',[
        Validators.required
      ]),
      name:new FormControl('',[
        Validators.required
      ]),
      currency:new FormControl('',[
        Validators.required
      ]),
      unitAmount:new FormControl('',[
        Validators.required
      ]),
      priceTypeId:new FormControl('',[
        Validators.required
      ]),
      promotionAmount:new FormControl('',[]),
      validAmount:new FormControl('',[
        Validators.required
      ])
    });
  }

  openConfirmCreatePrice(): void {
    if (this.form.valid) {
      this.openConfirm(`¿Seguro(a) que desea crear el precio al curso: ${this.course.course}?`).subscribe(
        (response) => {
          if (response) {
            Object.keys(this.form.controls).forEach(key => {
              this.form.controls[key].enable();
            });
            let data:any = {
              payment:this.form.value
            };
            if (this.priceTypeId == 1) {
              data.payment.promotionAmount = 0;
            }
            this.stripeCreatePrice(data);
          }
        }
      );
    } else {
      this.openDialog(`Complete el formulario de forma correcta por favor.`).subscribe(
        () => {this.form.markAllAsTouched();}
      )
    }
  }

  stripeCreatePrice(data:any): void {
    this.loader.open();
    this.service.stripeCreatePrice(data).subscribe(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        this.openDialog(`${body.message}`).subscribe(
          () => {
            if (body.success) this.getCoursePrices();
          }
        )
      },
      (error:HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(error);
      }
    );
  }

  activatePrice(cp:IStripePrice): void {
    this.openConfirm(`¿Seguro(a) que desea activar como precio principal?. Esta opción también modificará el precio en la plataforma stripe.`).subscribe(
      (response) => {
        if (response) {
          let data:any = {
            payment: {
              priceId:cp.priceId,
              name:this.course.course,
              productId:cp.productId,
              rcpmId:this.rcpm.rcpmId,
              priceTypeId:cp.priceTypeId,
              courseId:this.course.courseId,
              unitAmount:cp.unitAmount
            }
          };
          this.loader.open();
          this.service.stripeActivatePrice(data).subscribe(
            (response) => {
              this.loader.close();
              let body:IResponse = response.body;
              this.openDialog(`${body.message}`).subscribe(
                () => {
                  if (body.success) this.getCoursePrices();
                }
              )
            },
            (error:HttpErrorResponse) => {
              this.loader.close();
              this.proccessHttpResponse(error);
            }
          );
        }
      }
    );
  }

  unitAmountMsg:string = '';
  validateUnitAmount(unitAmount): boolean {
    let validAmount:string = '';
    try {
      let amount:number  = Number(unitAmount);
      this.unitAmountMsg = '';
      this.form.controls['validAmount'].setValue(validAmount);
      if (isNaN(amount)){
        this.unitAmountMsg = `* Verifique el monto ingresado por favor.`;
        return false;
      }
      if (amount <= 0) {
        this.unitAmountMsg = `* El monto ingresado debe ser mayor a 0.`;
        return false;
      }
      validAmount = unitAmount;
    } catch (error) {
      this.openDialog(`Ocurrió un error al validar el monto: ${error.message}. Favor de verificar.`); 
      return false;
    }
    this.form.controls['validAmount'].setValue(validAmount);
  }

  promotionAmountMsg:string = '';
  validatePromotionAmount(unitAmount): boolean {
    let validAmount:string = '';
    try {
      let amount:number  = Number(unitAmount);
      this.promotionAmountMsg = '';
      this.form.controls['validAmount'].setValue(validAmount);
      //
      if (isNaN(amount)){
        this.promotionAmountMsg = `* Verifique el monto de promoción ingresado por favor.`;
        return false;
      }
      if (amount <= 0) {
        this.promotionAmountMsg = `* El monto de promoción ingresado debe ser mayor a 0.`;
        return false;
      }
      let promotionAmount:number = Number(this.course.salePrice - amount);
      if (amount >= this.course.salePrice) {
        this.promotionAmountMsg = `* El monto de promoción ingresado no debe ser mayor o igual al monto del curso.`;
        return false;
      }
      if (promotionAmount < 0) {
        this.promotionAmountMsg = `* El monto de promoción (Curso - promoción) = (${this.course.salePrice - amount}) = ${promotionAmount}, genera un monto inválido.`;
        return false;
      }
      if (promotionAmount == 0) {
        this.promotionAmountMsg = `* El monto de promoción (Curso - promoción) = (${this.course.salePrice - amount}) = ${promotionAmount}, genera un monto en ceros.`;
        return false;
      }
      this.promotionAmountMsg = `Precio final de la promoción ${promotionAmount}`;
      this.form.controls['unitAmount'].setValue(promotionAmount);
      validAmount = unitAmount;
    } catch (error) {
      this.openDialog(`Ocurrió un error al validar el monto de promoción: ${error.message}. Favor de verificar.`); 
      return false;
    }
    this.form.controls['validAmount'].setValue(validAmount);
  }

}
