import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DialogAssignPaymentMethodComponent } from 'app/views/dialogs/dialog-assign-payment-method/dialog-assign-payment-method.component';
import { DialogManageStripePricesComponent } from 'app/views/dialogs/dialog-manage-stripe-prices/dialog-manage-stripe-prices.component';
import { BaseService, ICatPaymentMethods, ICourse, IRelCoursesPaymentsMethods, IResponse, IStripePriceType } from 'app/views/services/base.service';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.css']
})
export class PaymentMethodsComponent extends BaseService implements OnInit,AfterViewInit {

  course:ICourse = undefined;
  paymentMethods:ICatPaymentMethods[] = [];
  coursePaymentMethods:IRelCoursesPaymentsMethods[] = [];
  displayedColumns: string[] = ['id', 'course', 'paymentMethod', 'active', 'createdAt', 'actions'];
  dataSource: MatTableDataSource<IRelCoursesPaymentsMethods>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    injector:Injector
  ) { 
    super(injector);
    this.initDataSource();
  }

  initDataSource(): void {
    this.dataSource = new MatTableDataSource<IRelCoursesPaymentsMethods>([]);
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.paginator._intl = this.paginatorService.translatePaginator(this.paginator._intl);
  }

  page:number = 0;
  pageSize:number = 50;
  totalRows:number = 0;
  onPageChange(event){}

  redirect:string = 'dashboard/cmsCourses';
  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      try {
        if (!params || !params.hasOwnProperty('id')) {
          this.service.openDialog(`Ocurrió un error al obtener la información del curso. Favor de verificar.`).subscribe(
            () => {this.navigateTo(this.redirect)}
          ); return;
        }
        let courseId:number = params.id;
        this.getCoursePaymentMethods(courseId);
      } catch (error) {
        this.service.openDialog(`Ocurrió un error al obtener la información del curso: [${error.message}] Favor de verificar.`).subscribe(
          () => {this.service.navigateTo(this.redirect)}
        );
      }
    });
  }

  stripeId:number = 3;
  paymentMethodStripe:boolean = false;
  getCoursePaymentMethods(courseId:number): void {
    this.initDataSource();
    this.loader.open();
    this.service.getCoursePaymentMethods(courseId).subscribe(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        this.course = body.data.course;
        this.coursePaymentMethods = body.data.coursePaymentMethods;
        this.paymentMethods = body.data.paymentMethods;
        this.dataSource = new MatTableDataSource(this.coursePaymentMethods);
        if (this.dataSource.data.length <= 0) {
          this.openDialog(`No hay métodos de pago asignados al curso`);
        } else {
          let hasStripe = this.coursePaymentMethods.find(cpm => cpm.paymentMethodId === this.stripeId);
          if (hasStripe) this.paymentMethodStripe = true;
        }
      },
      (error:HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(error);
      }
    );
  }

  openDialogPaymentMethod(): void { 
    this.loader.open();
    this.service.getPaymentMethods().subscribe(
      (response:any) => {
        this.loader.close();
        let paymentMethods:any[] = response.data;
        if (paymentMethods.length <= 0) {
          this.openDialog(`No hay métodos de pago disponibles para asignar. Favor de verificar`);
          return;
        }
        paymentMethods.map(
          (pm) => {
            pm.paymentMethod = pm.payment_method
            delete pm.payment_method
          }
        );
        this.paymentMethods = paymentMethods;
        let pmStripe:ICatPaymentMethods = this.paymentMethods.find(pm => pm.id === 3);
        if (!pmStripe) {
          this.openDialog(`El método de pago Stripe no existe o no está activo para asignar. Favor de verificar.`);
          return;
        }
        this.openConfirm(`¿Seguro(a) que desea asignar el método de pago: Stripe?. Esta acción asignará el método de pago en 4x4 y creará el producto en la plataforma Stripe.`)
        .subscribe(
          (response) => {
            if (response) {
              let data = {
                payment:{
                  courseId: this.course.courseId,
                  name: this.course.course,
                  paymentMethodId: pmStripe.id
                }
              };
              this.stripeCreateProduct(data);
            }
          }
        );
      },
      (error:HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(error);
      }
    );
  }

  stripeCreateProduct(data:any): void {
    this.loader.open();
    this.service.stripeCreateProduct(data).subscribe(
      (response) => {
        this.loader.close();
        let body:IResponse = response.body;
        this.openDialog(`${body.message}`).subscribe(
          () => {
            if (body.success) this.getCoursePaymentMethods(this.course.courseId);
          }
        )
      },
      (error:HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(error);
      }
    );
  }

  openDialogStripePrices(rcpm:IRelCoursesPaymentsMethods): void {
    this.router.navigate(['/dashboard/cms/courses/stripe/prices/'], { 
      queryParams: { 
        courseId: this.course.courseId,
        rcpmId: rcpm.rcpmId
      }
    });
  }

}