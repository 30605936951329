import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SERVER, headers } from "../../../../../config";

@Injectable({
  providedIn: "root",
})

export class AuthenticationService {
  constructor(private http: HttpClient) {}

  testHttpRequest(): Observable<any> {
    // return this.http.get(SERVER); // PhP test request
    // const body = { email: 'dany@gmail.com', password: '123' };
    const body = { userId: "4", courseId: "2" };

    // Guardar datos al almacenamiento local actual
    localStorage.setItem("nombredeusuario", "John");

    // Acceder a datos almacenados
    alert("nombredeusuario = " + localStorage.getItem("nombredeusuario"));

    return this.http.put(SERVER + "/api/Courses/Inscription", body, {
      headers,
    });
  }


  public isLogged(){
    let user = JSON.parse(localStorage.getItem('infoUser'));
    return (user && user.hasOwnProperty('idUser'));
  }
  
}
