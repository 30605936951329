import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { egretAnimations } from 'app/shared/animations/egret-animations';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { AdminService } from 'app/views/services/admin.service';
import { RoleService } from 'app/views/services/role.service';
import { API } from 'app/views/shared/api';

@Component({
  selector: 'app-instructors',
  templateUrl: './instructors.component.html',
  styleUrls: ['./instructors.component.css'],
  animations: [egretAnimations]
})
export class InstructorsComponent implements OnInit {
  public instructors:Array<any> = [];
  
  constructor(
    private loader: AppLoaderService, 
    private router: Router, 
    private service: AdminService,
    public role: RoleService
  ) { }

  userInfo:any = {};
  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.getInstructors();
  }

  ngAfterViewInit(){
    let tutorialIsDisplayed = localStorage.getItem('tutorialInstructorsIsDisplayed');
    if(this.role.isClient() && tutorialIsDisplayed!='1'){
      localStorage.setItem('tutorialInstructorsIsDisplayed','1');
      this.service.showTutorialInstructorsClient();
    }
  }

  getInstructors(){
    this.loader.open('Buscando instructores');
    this.service.getInstructors().subscribe(
      (data:any)=>{
        this.instructors = data.data;
        if (this.instructors.length <= 0 || !this.instructors){
          let message:string = `No hay instructores cerca de ti.`;
          this.service.openDialog(message);
        }else{
          this.instructors.map((instructor)=>{
            instructor.image_url = API.URL_BASE_STORAGE+instructor.image_url;
          })
        }
      },
      (http:HttpErrorResponse) => {
        switch (http.status){
          case 0: 
            this.service.openDialog(`Servicio no disponible, intente más tarde, verifique su conexión o verifique con el área de soporte`);
            break;
          default:
            this.service.openDialog(`Error en el Servicio`);
            break;
        }
      },
      () => {this.loader.close()}
    )
  }

  onImgError(instructor:any){
    instructor.image_url = 'assets/images/perfil-usuario.png';
  }

  goToDetails(id:number){
    this.router.navigate(['/dashboard/instructor', id]);
  }

}
