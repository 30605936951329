<div fxLayout="row" fxLayoutAlign="space-between center" >
  <!-- <h2 mat-dialog-title  style="margin: 0px;">Sam el Mayorista</h2>
  <button mat-icon-button aria-label="close icon" [mat-dialog-close]="false"  >
      <mat-icon>close</mat-icon>
  </button> -->
</div>

<mat-dialog-content class="mat-typography" fxLayout="column" fxLayoutAlign="center center">
  <div fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex="60" fxFlex.md="80" fxFlex.sm="90" fxFlex.xs="90" fxLayoutAlign="center center">
        <img style="width: 90%;" mat-card-image [src]="roulette?.image ? roulette?.image : 'assets/images/game/ruleta/INICIO.png'">
    </div>
  </div>
  <mat-label>{{roulette?.text}}</mat-label>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button color="primary" [disabled]="roulette" (click)="spinRoulette()">Girar</button>
  <button mat-raised-button color="primary" [disabled]="!roulette || disableRouletteButton" [mat-dialog-close]="roulette">Aceptar</button>
  <!-- <button mat-raised-button color="primary" [disabled]="roulette" [mat-dialog-close]="false">Cancelar</button> -->
</mat-dialog-actions>