<p mat-dialog-title class="text-purple">Administrar de precios Stripe</p>
<mat-dialog-content>
  <div fxFlex="100" fxLayout="column" class="m-1">
    <div *ngIf="coursePrices?.length <= 0" fxFlex class="mb-2">
      <mat-label>El curso: <span class="text-bold text-purple">{{course?.course}}</span> no tiene precios asignados.</mat-label>
    </div>
    <div *ngIf="coursePrices?.length > 0" fxFlex class="mb-2">
      <mat-label>Precios generados para el curso: <span class="text-bold text-purple">{{course?.course}}</span>.</mat-label>
    </div>
    <div *ngIf="coursePrices?.length > 0"  fxFlex="100" fxLayout="column" class="main-container">
      <div fxFlex="100" fxLayout="row" class="border text-bold text-purple">
        <div fxFlex="42" fxLayoutAlign="center center">
          Monto:
        </div>
        <div fxFlex="12" fxLayoutAlign="center center">
          Moneda:
        </div>
        <div fxFlex="12" fxLayoutAlign="center center">
          Activo:
        </div>
        <div fxFlex="22" fxLayoutAlign="center center">
          Tipo:
        </div>
        <div fxFlex="22" fxLayoutAlign="center center" fxLayoutGap="1%">
          Acciones
        </div>
      </div>
      <div fxFlex="100" fxLayout="row" *ngFor="let cp of coursePrices" class="border">
        <div fxFlex="42" fxLayoutAlign="center center">
          <mat-label>Precio: {{cp.unitAmount}}<mat-icon class="color-purple cursor-pointer" matTooltip="Monto que está asignado al precio. Si se desea activar como principal. Ambos montos deben coincidir">help</mat-icon></mat-label> | Curso: <mat-label>{{cp.courseSalePrice}}<mat-icon class="color-purple cursor-pointer" matTooltip="Monto que está asignado al curso. Este monto se puede modificar en la sección de cursos">help</mat-icon></mat-label>
        </div>
        <div fxFlex="12" fxLayoutAlign="center center">
          <mat-label style="text-transform: uppercase;">{{cp.currency}}</mat-label>
        </div>
        <div fxFlex="12" fxLayoutAlign="center center">
          <mat-label style="text-transform: uppercase;">{{cp.active ? 'Si' : 'No'}}</mat-label>
        </div>
        <div fxFlex="22" fxLayoutAlign="center center">
          <mat-label style="text-transform: uppercase;">{{cp.priceType}} </mat-label>
        </div>
        <div fxFlex="22" fxLayoutAlign="center center" fxLayoutGap="1%">
          <button *ngIf="cp.active == 1" color="purple" type="button" mat-mini-fab matTooltip="Precio principal en 4x4 y Stripe">
            <mat-icon>check_circle</mat-icon>
          </button>
          <button 
            *ngIf="cp.active == 0 && cp.priceTypeId == 1" 
            type="button" 
            mat-mini-fab matTooltip="Definir como precio principal del curso"
            (click)="activatePrice(cp)">
            <mat-icon>check_circle</mat-icon>
          </button>
          <button 
            *ngIf="cp.priceTypeId == 2" 
            type="button"
            disabled
            mat-mini-fab 
            matTooltip="Definido como precio promocional">
            <mat-icon>check_circle</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <!---->
    <div fxFlex="100"><hr class="bg-purple"/></div>
    <!---->
    <div fxFlex class="mb-3">
      <mat-label>Agregar un nuevo precio:</mat-label>
      <p>
        <small>Si desea crear y activar el precio principal del curso en Stripe, el monto debe coincidir con el precio de venta del curso.</small>
        <br/>
        <small>El precio de venta del curso: {{course?.course}} es de: {{course.salePrice}} USD</small>
      </p>
      <p *ngIf="unitAmountMsg.length > 0" class="m-0 p-0">
        <small class="text-danger">{{unitAmountMsg}}</small>
      </p>
      <p *ngIf="promotionAmountMsg.length > 0" class="m-0 p-0">
        <small class="text-danger">{{promotionAmountMsg}}</small>
      </p>
    </div>
    <!---->
    <form [formGroup]="form" (ngSubmit)="openConfirmCreatePrice()">
      <div fxFlex="100" fxLayout="column" fxLayoutGap="1%" class="mt-2">
        <div fxFlex="100" fxLayout="row" fxLayoutGap="1%">
          <div fxFlex="33">
            <mat-form-field appearance="outline">
              <mat-label>Nombre del producto:</mat-label>
              <mat-icon class="color-purple" matPrefix>inventory</mat-icon>
              <input matInput formControlName="name">
              <mat-hint>Nombre que aparece en Stripe.</mat-hint>
            </mat-form-field>
          </div>
          <div fxFlex="33">
            <mat-form-field appearance="outline">
              <mat-label>Producto:</mat-label>
              <mat-icon class="color-purple" matPrefix>inventory_2</mat-icon>
              <input matInput formControlName="productId">
              <mat-hint>ID que aparece en Stripe.</mat-hint>
            </mat-form-field>
          </div>
          <div fxFlex="33">
            <mat-form-field appearance="outline">
              <mat-label>ID Curso:</mat-label>
              <mat-icon class="color-purple" matPrefix>description</mat-icon>
              <input matInput formControlName="courseId">
              <mat-hint>ID que aparece en 4x4.</mat-hint>
            </mat-form-field>
          </div>
        </div>
        <!---->
        <div fxFlex="100" fxLayout="row" fxLayoutGap="1%">
          <div fxFlex="98">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Tipo de precio:</mat-label>
              <mat-icon class="color-purple" matPrefix>price_change</mat-icon>
              <mat-select formControlName="priceTypeId" (selectionChange)="setPriceTypeId($event.value,_unitAmount.value)">
                <mat-option *ngFor="let pt of priceTypes" [value]="pt.id">{{pt.priceType}}</mat-option>
              </mat-select>
              <mat-icon class="color-purple" matSuffix matTooltip="Precio base: Es el monto predeterminado en cada curso. Promoción: es el monto que se asignará como promoción al curso elegido. Sólo para el método de pago con tarjetas (Stripe).">help</mat-icon>
              <mat-hint>Campo requerido por Stripe/4x4.</mat-hint>
            </mat-form-field>
          </div>
        </div>
        <!---->
        <div fxFlex="100" fxLayout="row" fxLayoutGap="1%">
          <div fxFlex="33">
            <mat-form-field appearance="outline" style="max-width: 220px;">
              <mat-label>Moneda:</mat-label>
              <mat-icon class="color-purple" matPrefix>shopping_bag</mat-icon>
              <mat-select formControlName="currency" (selectionChange)="setCurrency($event.value)">
                <mat-option *ngFor="let c of currencies" [value]="c.currency">{{c.currencyName}} | {{c.currency}}</mat-option>
              </mat-select>
              <mat-hint>Campo requerido por Stripe.</mat-hint>
            </mat-form-field>
          </div>
          <div fxFlex="33">
            <mat-form-field appearance="outline" style="max-width: 220px;">
              <mat-label>Monto:</mat-label>
              <mat-icon class="color-purple" matPrefix>monetization_on</mat-icon>
              <input #_unitAmount (input)="validateUnitAmount(_unitAmount.value)" matInput formControlName="unitAmount">
              <mat-icon class="color-purple" *ngIf="!currency" matSuffix>monetization_on</mat-icon>
              <div class="color-purple" *ngIf="currency" matSuffix style="text-transform: uppercase;font-size: 14px;font-weight: bold;transform: translate(-0%,-40%);">{{currency}}</div>
              <mat-hint>Campo requerido por Stripe.</mat-hint>
            </mat-form-field>
          </div>
          <div fxFlex="33">
            <mat-form-field appearance="outline" style="max-width: 220px;">
              <mat-label>Monto de la promoción:</mat-label>
              <mat-icon class="color-purple" matPrefix>monetization_on</mat-icon>
              <input #_promotionAmount (input)="validatePromotionAmount(_promotionAmount.value)" matInput formControlName="promotionAmount">
              <mat-icon class="color-purple" *ngIf="!currency" matSuffix>monetization_on</mat-icon>
              <div class="color-purple" *ngIf="currency" matSuffix style="text-transform: uppercase;font-size: 14px;font-weight: bold;transform: translate(-0%,-40%);">{{currency}}</div>
              <mat-hint>Campo requerido por Stripe.</mat-hint>
            </mat-form-field>
          </div>
        </div>
        <!---->
        <div fxFlex="100" fxLayout="row" fxLayoutGap="1%" fxLayoutAlign="center center">
          <button fxFlex="50" type="button" class="btn-purple" mat-button (click)="close()">
            <mat-label class="m-1">Cancelar</mat-label>
            <mat-icon aria-hidden="false">cancel_outline</mat-icon>
          </button>
          <button 
            [matTooltip]="course?.salePrice <= 0 ? 'El precio de venta del curso no puede ser menor o igual a 0. Diríjase a la sección [Cursos] y asigne un precio válido.' : ''" 
            fxFlex="50" type="submit" class="btn-green" [disabled]="form.invalid || course?.salePrice <= 0">
            <mat-label>Asignar precio</mat-label>
            <mat-icon class="m-1">check_circle_outline</mat-icon>
          </button>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
