import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CARDS } from 'app/views/dashboard/game/game.component';

@Component({
  selector: 'app-roulette-of-life',
  templateUrl: './roulette-of-life.component.html',
  styleUrls: ['./roulette-of-life.component.css']
})
export class RouletteOfLifeComponent implements OnInit {
  public currentCard:any;
  constructor(private dialogRef: MatDialogRef<RouletteOfLifeComponent>,@Inject(MAT_DIALOG_DATA) public data:any) { }

  ngOnInit(): void {
  }

  selectCard():void{
    let cardsFilters:Array<any> = JSON.parse(JSON.stringify(CARDS));
    if(this.data?.cards_of_life_1 && this.data?.cards_of_life_1!=""){
      cardsFilters = cardsFilters.filter(cf=>cf.text.trim()!=this.data.cards_of_life_1.trim());
    }
    if(this.data?.cards_of_life_2 && this.data?.cards_of_life_2!=""){
      cardsFilters = cardsFilters.filter(cf=>cf.text.trim()!=this.data.cards_of_life_2.trim());
    }
    let cardIndex:number = Math.floor(Math.random() * (cardsFilters.length+1));
    if (cardIndex >= 0 || cardIndex <= cardsFilters.length){
      this.currentCard = cardsFilters[cardIndex];
    }
  }

}
