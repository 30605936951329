<div fxLayout="row" fxLayoutAlign="flex-end flex-start">
    <h1 mat-dialog-title style="color: #e53935;width: 100%;text-align: center;">La contabilidad del modelo empresarial
    </h1>
    <button mat-icon-button aria-label="close icon" (click)="dismiss()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-content fxLayout="row" fxLayoutAlign="space-between stretch">
    <div fxLayout="column" style="max-height: 80%;" fxFlex="78">
        <div fxLayout="row" fxLayoutAlign="space-between start" style="max-height: 46% !important;">
            <div fxFlex="31" fxLayout="column" fxLayoutAlign="center stretch">
                <mat-chip-list style="width: 100%;justify-content: center;align-items: center;display: flex;">
                    <mat-chip style="border-bottom-left-radius: 0px;" color="primary">DINERO QUE ENTRO</mat-chip>
                </mat-chip-list>
                <p style="margin-top: 5px;color: #e53735; font-weight: bold;">Préstamo bancario, ventas al contado, pago
                    de créditos </p>
                <table class="table table-bordered table-responsive-md table-striped text-center">
                    <tr>
                        <th style="width: 65px;" class="text-center">Fecha</th>
                        <th class="text-center">El dinero vino de</th>
                        <th class="text-center">Total</th>
                    </tr>
                    <tr *ngFor="let p of tablaUno; let id = index">
                        <td>
                            <!-- <span style="font-weight: bold;" contenteditable="true" class="spandates"
                                (keyup)="changeValue(id, 'fecha', $event)"
                                (blur)="updateList(id, 'fecha', $event);validateLongitud()">{{p.fecha}}</span> -->
                            <mat-select name="miselect" [(ngModel)]="p.fecha" [ngModelOptions]="{standalone: true}" >
                                <mat-option [value]="day" *ngFor="let day of selectDays">{{day}}</mat-option>
                            </mat-select>
                        </td>
                        <td>
                            <span style="font-weight: bold;" contenteditable="true" class="spantext"
                                (keyup)="changeValue(id, 'dineroVinoDe', $event)"
                                (blur)="updateList(id, 'dineroVinoDe', $event);validateLongitud()">{{p.dineroVinoDe}}</span>
                        </td>
                        <td style="font-weight: bold;">
                            <span contenteditable="true" class="spannumbers" (keyup)="changeValue(id, 'total', $event)"
                                (blur)="updateList(id, 'total', $event);validateNumberLongitud()">{{p.total}}</span>{{p.total>0?'LK':''}}
                        </td>
                    </tr>
                </table>
            </div>
            <div fxFlex="31" fxLayout="column" fxLayoutAlign="center stretch">
                <mat-chip-list style="width: 100%;justify-content: center;align-items: center;display: flex;">
                    <mat-chip style="border-bottom-right-radius: 0px;" color="primary">PLAN SUSANA SENSATA</mat-chip>
                </mat-chip-list>
                <table style="margin-top: 42px;"
                    class="table table-bordered table-responsive-md table-striped text-center">
                    <tr>
                        <th class="text-center">Negocios</th>
                        <th class="text-center">Gastos</th>
                        <th class="text-center">Ahorros</th>
                    </tr>
                    <tr *ngFor="let p of tablaDos; let id = index">
                        <td>
                            <span style="font-weight: bold;" contenteditable="true" class="spantext"
                                (keyup)="changeValue(id, 'negocios', $event)"
                                (blur)="updateListDos(id, 'negocios', $event);validateLongitud()">{{p.negocios}}</span>
                        </td>
                        <td style="font-weight: bold;">
                            <span contenteditable="true" class="spannumbers" (keyup)="changeValue(id, 'gastos', $event)"
                                (blur)="updateListDos(id, 'gastos', $event);validateNumberLongitud()">{{p.gastos}}</span>{{p.gastos>0?'LK':''}}
                        </td>
                        <td style="font-weight: bold;">
                            <span contenteditable="true" class="spannumbers"
                                (keyup)="changeValue(id, 'ahorros', $event)"
                                (blur)="updateListDos(id, 'ahorros', $event);validateNumberLongitud()">{{p.ahorros}}</span>{{p.ahorros>0?'LK':''}}
                        </td>
                    </tr>
                </table>
            </div>
            <div fxFlex="31" fxLayout="column" fxLayoutAlign="center stretch">
                <mat-chip-list style="width: 100%;justify-content: center;align-items: center;display: flex;">
                    <mat-chip style="border-bottom-right-radius: 0px;" color="primary">DINERO QUE TODAVÍA DEBO PAGAR
                    </mat-chip>
                </mat-chip-list>
                <table style="margin-top: 42px;"
                    class="table table-bordered table-responsive-md table-striped text-center">
                    <tr>
                        <th style="width: 65px;" class="text-center">Fecha</th>
                        <th class="text-center">Pagué por</th>
                        <th class="text-center">Negocios</th>
                        <th class="text-center">Gastos</th>
                    </tr>
                    <tr *ngFor="let p of tablaTres; let id = index">
                        <td>
                            <!-- <span style="font-weight: bold;" contenteditable="true" class="spandates"
                                (keyup)="changeValue(id, 'fecha', $event)"
                                (blur)="updateListTres(id, 'fecha', $event);validateDate()">{{p.fecha}}</span> -->
                            <mat-select name="miselect" [(ngModel)]="p.fecha" [ngModelOptions]="{standalone: true}" >
                                <mat-option [value]="day" *ngFor="let day of selectDays">{{day}}</mat-option>
                            </mat-select>
                        </td>
                        <td>
                            <span style="font-weight: bold;" contenteditable="true" class="spantext"
                                (keyup)="changeValue(id, 'paguePor', $event)"
                                (blur)="updateListTres(id, 'paguePor', $event);validateLongitud()">{{p.paguePor}}</span>
                        </td>
                        <td>
                            <span style="font-weight: bold;" contenteditable="true" class="spantext"
                                (keyup)="changeValue(id, 'negocios', $event)"
                                (blur)="updateListTres(id, 'negocios', $event);validateLongitud()">{{p.negocios}}</span>
                        </td>
                        <td style="font-weight: bold;">
                            <span contenteditable="true" class="spannumbers" (keyup)="changeValue(id, 'gastos', $event)"
                                (blur)="updateListTres(id, 'gastos', $event);validateNumberLongitud()">{{p.gastos}}</span>{{p.gastos>0?'LK':''}}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between start" style="max-height: 46% !important;">
            <div fxFlex="31">
                <mat-chip-list>
                    <mat-chip style="background-color: #6a1b9a !important;color: white;border-bottom-left-radius: 0px;">
                        RESULTADOS</mat-chip>
                </mat-chip-list>
                <br>
                <div fxLayout="row" fxLayoutAlign="space-around stretch">
                    <mat-chip-list class="mat-chip-list-stacked" style="width: 100%;">
                        <div style="width: 100%; position: relative;">
                            <mat-chip style="width: 100%; background-color: #fff !important; color:#6a1b9a !important;">
                                Ingresos
                            </mat-chip>
                            <span contenteditable="true"
                                style="font-weight: bold; text-align: right; position: absolute; width: 90px; right: 5px; padding-right: 5px; top: 10px; z-index: 1;"
                                class="spannumberstotales"
                                (blur)="validateNumberTotales($event,1)">{{totales.ingresos}}</span>
                        </div>
                        <div style="width: 100%; position: relative;">
                            <mat-chip style="width: 100%; background-color: #fff !important; color:#6a1b9a !important;">
                                menos costos comerciales
                            </mat-chip>
                            <span contenteditable="true"
                                style="font-weight: bold; text-align: right; position: absolute; width: 90px; right: 5px; padding-right: 5px; top: 10px; z-index: 1;"
                                class="spannumberstotales"
                                (blur)="validateNumberTotales($event,2)">{{totales.costoscomerciales}}</span>
                        </div>
                        <div style="width: 100%; position: relative;">
                            <mat-chip style="width: 100%; background-color: #fff !important; color:#6a1b9a !important;">
                                = ganancia neta
                            </mat-chip>
                            <span contenteditable="true"
                                style="font-weight: bold; text-align: right; position: absolute; width: 90px; right: 5px; padding-right: 5px; top: 10px; z-index: 1;"
                                class="spannumberstotales"
                                (blur)="validateNumberTotales($event,3)">{{totales.ganancianeta}}</span>
                        </div>
                        <div style="width: 100%; position: relative;">
                            <mat-chip style="width: 100%; background-color: #fff !important; color:#6a1b9a !important;">
                                menos gastos
                            </mat-chip>
                            <span contenteditable="true"
                                style="font-weight: bold; text-align: right; position: absolute; width: 90px; right: 5px; padding-right: 5px; top: 10px; z-index: 1;"
                                class="spannumberstotales"
                                (blur)="validateNumberTotales($event,4)">{{totales.gastos}}</span>
                        </div>
                        <div style="width: 100%; position: relative;">
                            <mat-chip style="width: 100%; background-color: #fff !important; color:#6a1b9a !important;">
                                = excedente
                            </mat-chip>
                            <span contenteditable="true"
                                style="font-weight: bold; text-align: right; position: absolute; width: 90px; right: 5px; padding-right: 5px; top: 10px; z-index: 1;"
                                class="spannumberstotales"
                                (blur)="validateNumberTotales($event,5)">{{totales.excedente}}</span>
                        </div>
                    </mat-chip-list>
                </div>
            </div>
            <div fxFlex="31" fxLayout="column" fxLayoutAlign="center stretch">
                <mat-chip-list style="width: 100%;justify-content: center;align-items: center;display: flex;">
                    <mat-chip style="border-bottom-right-radius: 0px;" color="primary">QUIEN ME DEBE DINERO</mat-chip>
                </mat-chip-list>
                <table style="margin-top: 15px;"
                    class="table table-bordered table-responsive-md table-striped text-center">
                    <tr>
                        <th class="text-center">Quién me debe</th>
                        <th class="text-center">Cantidad</th>
                        <th style="width: 65px;" class="text-center">Fecha de Pago</th>

                    </tr>
                    <tr *ngFor="let p of tablaCinco; let id = index">
                        <td>
                            <span style="font-weight: bold;" contenteditable="true" class="spantext"
                                (keyup)="changeValue(id, 'quienMeDebe', $event)"
                                (blur)="updateListCinco(id, 'quienMeDebe', $event);validateLongitud()">{{p.quienMeDebe}}</span>
                        </td>
                        <td style="font-weight: bold;">
                            <span contenteditable="true" class="spannumbers"
                                (keyup)="changeValue(id, 'cantidad', $event)"
                                (blur)="updateListCinco(id, 'cantidad', $event);validateNumberLongitud()">{{p.cantidad}}</span>{{p.cantidad>0?'LK':''}}
                        </td>
                        <td>
                            <!-- <span style="font-weight: bold;" contenteditable="true" class="spandates"
                                (keyup)="changeValue(id, 'fechaDePago', $event)"
                                (blur)="updateListCinco(id, 'fechaDePago', $event);validateDate()">{{p.fechaDePago}}</span> -->

                            <mat-select name="miselect" [(ngModel)]="p.fecha" [ngModelOptions]="{standalone: true}" >
                                <mat-option [value]="day" *ngFor="let day of selectDays">{{day}}</mat-option>
                            </mat-select>                                
                        </td>
                    </tr>
                </table>
            </div>
            <div fxFlex="31" fxLayout="column" fxLayoutAlign="center stretch">
                <mat-chip-list style="width: 100%;justify-content: center;align-items: center;display: flex;">
                    <mat-chip style="border-bottom-right-radius: 0px;" color="primary">DINERO QUE TODAVIA DEBO COBRAR
                    </mat-chip>
                </mat-chip-list>

                <table style="margin-top: 15px;"
                    class="table table-bordered table-responsive-md table-striped text-center">
                    <tr>
                        <th class="text-center">A quién</th>
                        <th class="text-center">Cantidad</th>
                        <th style="width: 65px;" class="text-center">Fecha de Pago</th>
                    </tr>
                    <tr *ngFor="let p of tablaSeis; let id = index">
                        <td>
                            <span style="font-weight: bold;" contenteditable="true" class="spantext"
                                (keyup)="changeValue(id, 'aQuien', $event)"
                                (blur)="updateListSeis(id, 'aQuien', $event);validateLongitud()">{{p.aQuien}}</span>
                        </td>
                        <td style="font-weight: bold;">
                            <span contenteditable="true" class="spannumbers"
                                (keyup)="changeValue(id, 'cantidad', $event)"
                                (blur)="updateListSeis(id, 'cantidad', $event);validateNumberLongitud()">{{p.cantidad}}</span>{{p.cantidad>0?'LK':''}}
                        </td>
                        <td>
                            <!-- <span style="font-weight: bold;" contenteditable="true" class="spandates"
                                (keyup)="changeValue(id, 'fechaDePago', $event)"
                                (blur)="updateListSeis(id, 'fechaDePago', $event);validateDate()">{{p.fechaDePago}}</span> -->
                            <mat-select name="miselect" [(ngModel)]="p.fecha" [ngModelOptions]="{standalone: true}" >
                                <mat-option [value]="day" *ngFor="let day of selectDays">{{day}}</mat-option>
                            </mat-select>
                        
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="stretch stretch" fxFlex="20">
        <mat-chip-list style="width: 100%;justify-content: center;align-items: center;display: flex;">
            <mat-chip style="border-bottom-right-radius: 0px;" color="primary">AHORROS</mat-chip>
        </mat-chip-list>
        <table style="margin-top: 42px;" class="table table-bordered table-responsive-md text-center" id="tablaAhorro">
            <tr>
                <th class="text-center">Día</th>
                <th class="text-center">Cantidad</th>
            </tr>
            <tr *ngFor="let p of tablaCuatro; let id = index">
                <td>
                    <mat-select name="miselect" [(ngModel)]="p.ahorroUno" [ngModelOptions]="{standalone: true}" >
                        <mat-option [value]="day" *ngFor="let day of selectDays">{{day}}</mat-option>
                    </mat-select>
                </td>
                <td style="font-weight: bold;">
                    <span contenteditable="true" class="spannumbers" (keyup)="changeValue(id, 'ahorroDos', $event)"
                        (blur)="updateListCuatro(id, 'ahorroDos', $event);validateNumberLongitud()">{{p.ahorroDos}}</span>
                    {{p.ahorroDos>0?' LK':''}}
                </td>
            </tr>
        </table>
    </div>
</div>