<div fxLayout="row" fxLayoutAlign="space-between center" >
  <h2 mat-dialog-title style="margin: 0px;">Lista de Productos Comprados</h2>
  <button mat-icon-button aria-label="close icon" [mat-dialog-close]="true"  >
      <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content class="mat-typography" fxLayout="column" fxLayoutAlign="center center" style="min-height: 40%;">
  <div fxLayout="row" fxLayoutAlign="center center" fxFlex="100" style="width: 100%;">
    <div *ngIf="products.length > 0 "  [ngStyle.xs]="{'margin-top': '7%'}" fxFlex="100" style="height: 215px !important; padding:0; margin:0; box-sizing:border-box; width:100%; overflow-y: scroll; margin-bottom: 25px;">
      <div *ngFor="let product of products" style="display:inline-block; width:95px;">
          <div fxLayoutAlign="center center" fxLayout="column" [ngStyle]="{'background-color': product?.isSelected==1 ? '#c8c8c8' : 'white', 'color': 'black' }" (click)="this.role.isClient() == false ? selectedProduct(product) : return; ">
              <img [src]="product?.image_url" alt="" style="height: 70px; width: 70px;">
              <label>${{product?.price}}</label>
              <h6>{{product?.virtual_product}}</h6>
          </div>
      </div>
    </div>
    <h2 *ngIf="products.length == 0 ">NO SE HAN ENCONTRADO PRODUCTOS</h2>
  </div>
  
</mat-dialog-content>
<mat-dialog-actions align="center">
  
  <button mat-raised-button color="primary" [mat-dialog-close]="true">Aceptar</button>
  
</mat-dialog-actions>