import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {
  viewButton:Boolean=false;
  onlyOk:boolean=false;
  constructor(
    public dialogo: MatDialogRef<DialogComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {}

  ngOnInit(): void {
    //console.log(this.data);
    this.onlyOk = this.data.onlyOk;
    this.viewButton = this.data.viewButton;
  }
  cerrarDialogo(): void {
    this.dialogo.close(false);
  }
  confirmado(): void {
    this.dialogo.close(true);
  }
  sanitizeText(text:string) {
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }
}

export interface DialogData {
  title: string;
  content: string;
  at:string;
  viewButton:boolean;
  onlyOk:boolean;
}
