<div fxFlex="100" fxLayoutAlign="center center" fxLayout="column">
  <div id="calendar-game" fxFlex="100" fxLayout="column" fxLayoutAlign="center center" style="width: 100%;">
		<div class="m-2" fxFlex="100" fxLayoutAlign="center center" style="width: 100%;">
			<mat-grid-list cols="3" rows="2" rowHeight="40px" fxFlex="90" fxLayoutAlign="center center" style="width: 100%;">
				<mat-grid-tile (click)="openCalendarInfoDialog()" style="border-radius: 15px 0 0 15px; box-shadow: 5px 5px 5px 2px #cecece;" [colspan]="1" [rowspan]="2" class="bg-purple text-light cursor-pointer">
					<h3>Módulo 4: Semana {{ this.week }} </h3>
				</mat-grid-tile>
				<mat-grid-tile (click)="openCalendarInfoDialog()" [colspan]="1" [rowspan]="2" class="bg-purple text-light cursor-pointer" style="box-shadow: 5px 5px 5px 2px #cecece;">
					<div
						style="display: flex; flex-direction: row; justify-content: space-evenly; align-items: center;width: 80%; ">
						<mat-icon style="cursor: pointer;">insert_invitation</mat-icon>
						<p style="margin-bottom: 0rem; font-size:2em;">{{dayName(calendarDay?.id)}}</p>
						<p style="margin-bottom: 0rem; font-size:2em;">{{calendarDay?.id}}</p>
					</div>
				</mat-grid-tile>
				<mat-grid-tile style="border-radius: 0 15px 15px 0; box-shadow: 5px 5px 5px 2px #cecece;" [colspan]="1" [rowspan]="2">
					<div style="height: 100%; width: 100%;">
						<mat-grid-list cols="2" rows="2" rowHeight="20px">
							<mat-grid-tile [colspan]="2" [rowspan]="2" class="bg-purple text-light" style="box-shadow: 5px 5px 5px 2px #cecece;">
								{{calendarDay?.objective}}
							</mat-grid-tile>
							<mat-grid-tile [colspan]="2" [rowspan]="2" class="bg-purple text-light">
								{{calendarDay?.summary}}
							</mat-grid-tile>
						</mat-grid-list>
					</div>
				</mat-grid-tile>
			</mat-grid-list>
		</div>
		<div *ngIf="calendarDay?.id==30" class="m-1" fxFlex="100">
			<button type="button" mat-flat-button class="btn-purple" (click)="saveGameSession()">
				<mat-label>Guardar estado de la partida</mat-label>
				<mat-icon>save</mat-icon>
			</button>
		</div>
		<div class="m-1" fxFlex="100">
			<button #_btnBack type="button" mat-flat-button class="btn-calendar-green m-2" (click)="backDay(_btnBack)" [disabled]="calendarDay?.id <= 1">
				<mat-icon>arrow_back</mat-icon>
				<mat-label>Día anterior</mat-label>
			</button>
			<button #_btnFwrd type="button" mat-flat-button class="btn-calendar-green m-2" (click)="changeDay(_btnFwrd)" [disabled]="calendarDay?.id >= 30">
					<mat-label>Día siguiente</mat-label>
					<mat-icon>arrow_forward</mat-icon>
			</button>
		</div>
	</div>
    <div fxLayout="row" style="width: 100%; padding-left: 5%; padding-right: 5%; margin-bottom: 30px;"
        fxLayout.sm="column" fxLayout.xs="column">
        <!-- ================ LISTA DE ALUMNOS ================ -->
        <div fxLayout="column" fxLayoutAlign="space-between center"
            style="float: left; margin-right: 5%; text-align: center; color: black; border-radius: 10px; border: 2px solid black; padding: 10px; max-height: 570px;">
            <div style="width: 400px; font-weight: bold; color: black; font-size: 1rem;">
                <h4 style="background-color: #7ea920; color: white;">LISTA DE ALUMNOS</h4>
            </div>
            <div style="overflow: auto; width: 400px; max-height: 420px; height: 100%; font-weight: bold; color: black; font-size: 1rem;">
                <table>
                    <tr>
                        <td># DE EQUIPOS</td>
                        <td>{{ montosXEquipo.length }}</td>
                        <td style="width: 60px;">Lider</td>
                    </tr>
                    <tr *ngFor="let user of users">
                        <!-- ESTUDIANTES ROJO -->
                        <!-- <td style="font-weight: bold;">{{ user.name }} {{ user.last_name }} ({{ user.email }})</td> -->
                        <!-- [style.background-color]="getColorValue(user)" style="color: white;"  -->
                        <td style="font-weight: bold;">{{ user.name }} {{ user.last_name }}</td>
                        <td>
                            <mat-form-field appearance="fill" style="max-width: 170px; height: 58px; border-radius: 5px;" [ngStyle]="{'background':getColorByTeams(user.team)}">
                                <mat-label [style.color]="services.getColorByBackgroundColor(getColorByTeams(user.team))">Seleccione un equipo: </mat-label>
                                <mat-select matInput [(ngModel)]="user.team" (selectionChange)="user.leader=false" class="{{services.getColorByBackgroundColor(getColorByTeams(user.team))}}-select">
                                    <mat-option *ngFor="let color of colors" 
                                        [ngStyle]="{'background-color': color.color }"
                                        [style.color]="services.getColorByBackgroundColor(color.color)"
                                        [value]="color.team">{{color.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td>
                            <div fxLayout="row" fxLayoutAlign="center center"
                                style="margin-left: 20px; margin-bottom: 20px; position: relative;">
                                <input class="form-check-input" type="radio" [name]="user.team" [checked]="user.leader"
                                    (change)="selectedLider($event,user)">
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <button mat-button class="btn btn-purple" (click)="changeColorUsers()" style="width: 100%; margin-top: 20px;">
                <mat-icon>save </mat-icon>
                Guardar
            </button>
        </div>
        <!-- ======================= EQUIPO ROJO ========================== -->
        <div *ngFor="let eq of montosXEquipo; let i = index" fxFlex.xs="100" fxFlex.sm="100"
            fxLayoutAlign.xs="center center" fxLayoutAlign.sm="center center">
            <div style="width: auto; float: left; margin-right: 5%; text-align: center; color: white; border-radius: 10px; border: 2px solid black; padding: 10px; max-height: 570px; overflow: auto;" *ngIf="haveUsersInTeam(eq.team)">
                <h4 [style.background-color]="getColorValue(eq)" style="width: 100%; margin: 0; padding: 0;"
                [ngStyle]=" getColorValue(eq) == '#fff' || getColorValue(eq) == '#ffffff' ? {'color': 'black', 'font-weight': 'bold'} : {'color': 'white', 'font-weight': 'bold'} "
                    [value]="eq.team">{{(eq.team)?eq?.name:'SinEquipo'}}</h4>
                <div id="teamRed" fxLayout="column" style="color: black !important; margin-bottom: 10px;">
                    <div fxFlex="100" fxLayout="row">
                        <div style="font-weight: bold; color: black; width: 50%;">Imagen</div>
                        <div style="font-weight: bold; color: black; width: 50%;">Nombre</div>
                    </div>
                    <div fxLayout="column" style="max-height: 190px; overflow: auto; width: 100%;">
                        <div *ngFor="let student of users" style="margin: 5px; color: black !important;" fxLayout="row">
                            <div fxFlex="100" fxLayout="row" *ngIf="eq.team == student.team">
                                <div fxFlex="50">
                                    <img mat-card-avatar
                                        style="border-radius: 50%; width: 35px; height: 35px; object-fit:cover; min-height:35px !important; min-width: 35px !important;"
                                        [src]="getUrlImage(student.image_url)"
                                        onError="this.src='./assets/images/perfil-usuario.png'">
                                </div>
                                <div fxFlex="50">
                                    <span style="font-weight: bold; color: black;">{{student.name +" "+ student.last_name }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <table *ngIf="(eq.team)" style="color: black;">
                    <tr>
                        <td style="width: 50%;font-weight: bold; color: black;">Leekels:</td>
                        <td>
                            <input #_totalLeekels class="inputTypeMat" type="number" id="leekels{{eq.team}}" [value]="(eq.team)? eq.totalLeekels : 0" (change)="getTotalesLeekels(_totalLeekels.value,_investment.value , _spend.value , _saving.value,i)"><br>
                            <ng-template [ngIf]="!eq?.totalIsOk">
                                <span class="small color-red">* La suma de IGA es {{getSumIGA(_investment.value, _spend.value,_saving.value)}}.</span>
                            </ng-template>
                        </td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold; color: black;">Inversión:</td>
                        <td><input #_investment class="inputTypeMat" type="number" id="inversion{{eq.team}}"
                                (change)="getTotalesLeekels(_totalLeekels.value,_investment.value , _spend.value , _saving.value,i)"
                                [value]="(eq.team)?eq.inversion:0" min="0"
                                oninput="(validity.valid)||(value='');"></td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold; color: black;">Gastos:</td>
                        <td><input #_spend class="inputTypeMat" type="number" id="gastos{{eq.team}}"
                                (change)="getTotalesLeekels(_totalLeekels.value,_investment.value , _spend.value , _saving.value,i)"
                                [value]="(eq.team)?eq.gasto:0" min="0" oninput="(validity.valid)||(value='');">
                        </td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold; color: black;">Ahorro:</td>
                        <td><input #_saving class="inputTypeMat" type="number" id="ahorro{{eq.team}}"
                                (change)="getTotalesLeekels(_totalLeekels.value,_investment.value , _spend.value , _saving.value,i)"
                                [value]="(eq.team)?eq.ahorro:0" min="0"
                                oninput="(validity.valid)||(value='');"></td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold; color: black;">Deuda:</td>
                        <td>
                            <input #_debt class="inputTypeMat" type="number" id="deuda{{eq.team}}" [value]="(eq.team)?eq.deuda:0" min="0" placeholder="0" oninput="(validity.valid)||(value='');">
                        </td>
                    </tr>
                    <tr>
                        <td style="font-weight: bold; color: black;">Materia Prima:</td>
                        <td>
                            <input #_rawMaterial class="inputTypeMat" id="materia{{eq.team}}" type="number" [value]="(eq.team)?eq.materiaPrima:0" min="0" placeholder="0" oninput="(validity.valid)||(value='');">
                        </td>
                    </tr>
                    <tr *ngIf="!isDistributor(eq?.team)">
                        <td style="font-weight: bold; color: black;">Caso 'Sam el Mayorista':</td>
                    </tr>
                    <tr *ngIf="!isDistributor(eq?.team)">
                        <td colspan="2">
                            <label *ngIf="eq?.roulette_sam_1" style="width: 300px; font-weight: bold; text-align: left;">Condición 1: {{eq?.roulette_sam_1}}</label><br>
                            <label *ngIf="eq?.roulette_sam_2" style="width: 300px; font-weight: bold; text-align: left;">Condición 2: {{eq?.roulette_sam_2}}</label><br>
                            <label *ngIf="eq?.roulette_sam_3" style="width: 300px; font-weight: bold; text-align: left;">Condición 3: {{eq?.roulette_sam_3}}</label>
                        </td>
                    </tr>
                    <!-- <tr>
                        <td style="font-weight: bold; color: black;">Tarjeta de la Vida:</td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <label *ngIf="eq?.card?.text" style="width: 300px; font-weight: bold;">{{eq?.card?.text}}</label>
                        </td>
                    </tr> -->
                    <tr>
                        <td></td>
                        <td style="border: 1px white solid; display: flex; justify-content: flex-end;">
                            <button style="background-color: #7ecb20;" type="button" mat-button [disabled]="(!eq.team)"
                                (click)="confirmUpdateLeekelsByTeam(eq,_investment.value,_spend.value,_saving.value,_totalLeekels.value,_debt.value,_rawMaterial.value)">
                                <small style="color: black;">Actualizar</small>
                            </button>
                            <button type="button" mat-button hidden id="submit{{eq.team}}"
                                (click)="updateLeekelsByTeamAutomatic(eq,_investment.value,_spend.value,_saving.value,_totalLeekels.value,_debt.value,_rawMaterial.value)">
                                <small style="color: black;">Mockup Button</small>
                            </button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div fxFlex.xs="100" fxFlex.sm="90" fxLayout.sm="column" fxLayout.xs="column"
        style="width: 100%; padding-left: 5%; padding-right: 5%; margin-bottom: 30px; margin-top:3%;">
        <!-- ======================= CALENDARIO ============================ -->
        <div [ngStyle.xs]="{'width': '100%' }" [ngStyle.sm]="{'width': '100%' }" fxFlex.xs="100" fxFlex.sm="90" class="cursor-pointer"
            style="min-width: 25%; width: 25%; float: left; background-color: whitesmoke; color: white; border-radius: 10px; margin-top: 45px;"
            (click)="openCalendarInfoDialog()">
            <table fxFlex="100"
                style="text-align: center; height: 200px; border: 2px solid black; border-radius: 10px; width: auto;">
                <tr style="background-color: darkred; font-weight: bold; padding: 10px 0 1px 0;">
                    <th>L</th>
                    <th>M</th>
                    <th>MI</th>
                    <th>J</th>
                    <th>V</th>
                    <th>S</th>
                    <th>D</th>
                </tr>
                <tr style="color: black;">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td id="day1">1</td>
                    <td id="day2">2</td>
                    <td id="day3">3</td>
                    <td id="day4">4</td>
                </tr>
                <tr style="color: black;">
                    <td id="day5">5</td>
                    <td id="day6">6</td>
                    <td id="day7">7</td>
                    <td id="day8">8</td>
                    <td id="day9">9</td>
                    <td id="day10">10</td>
                    <td id="day11">11</td>
                </tr>
                <tr style="color: black;">
                    <td id="day12">12</td>
                    <td id="day13">13</td>
                    <td id="day14">14</td>
                    <td id="day15">15</td>
                    <td id="day16">16</td>
                    <td id="day17">17</td>
                    <td id="day18">18</td>
                </tr>
                <tr style="color: black;">
                    <td id="day19">19</td>
                    <td id="day20">20</td>
                    <td id="day21">21</td>
                    <td id="day22">22</td>
                    <td id="day23">23</td>
                    <td id="day24">24</td>
                    <td id="day25">25</td>
                </tr>
                <tr style="color: black;">
                    <td id="day26">26</td>
                    <td id="day27">27</td>
                    <td id="day28">28</td>
                    <td id="day29">29</td>
                    <td id="day30">30</td>
                    <td></td>
                    <td></td>
                </tr>
            </table>
        </div>
        <!--- ================= TIENDA DE LUIS ================== -->
        <!-- <div *ngIf="dayIn(calendarDay?.id,saturdayDays)" fxLayoutAlign.xs="center center"
            fxLayoutAlign.sm="center center" fxLayoutAlign="space-between center" fxLayout="column" fxFlex="75"
            fxFlex.xs="100" fxFlex.sm="100" fxLayout.sm="column" fxLayout.xs="column"
            style=" padding: 10px; float: right; text-align: center; right: 0; top:60%;width: 100%;">
            <h4>LA EMPRESA DE CARLOS</h4>
            <div fxLayoutAlign.xs="center center" fxLayout.sm="column" fxLayout.xs="column"
                fxLayoutAlign="space-between start" fxLayout="row" style="width: 100%;">
                <div [ngStyle.xs]="{'width': '100%' }" [ngStyle.sm]="{'width': '100%' }"
                    style="padding:0; margin:24px; box-sizing:border-box; width:100%; max-height: 400px !important; overflow-y: scroll;">
                    <div *ngFor="let product of products" style="display:inline-block; width:95px;">
                        <div fxLayoutAlign="center center" fxLayout="column"
                            [ngStyle]="{'background-color': product?.isSelected==1 ? '#c8c8c8' : 'white', 'color': 'black' }"
                            (click)="selectedProduct(product)">
                            <img [src]="product?.image_url" alt="" style="height: 70px; width: 70px;">
                            <label>${{product?.price}}</label>
                            <h6>{{product?.virtual_product}}</h6>
                        </div>
                    </div>
                </div>
                <div [ngStyle.sm]="{'width': '100%' }" fxLayoutAlign.sm="center center" fxFlex.xs="100" fxFlex.sm="100"
                    fxFlex="30" fxLayout="column" fxLayoutAlign="center center" style="width: 30%;">
                    <div style="text-align: center; color: white;background-color: white; border-radius: 10px;"
                        *ngIf="role.isInstructor()">
                        <mat-form-field appearance="outline">
                            <mat-label style="color: black;">Seleccione un equipo: </mat-label>
                            <mat-select matInput [(ngModel)]="teamSelected">
                                <mat-option *ngFor="let color of colors"
                                    [ngStyle]="{'background-color': color.color, 'color': 'white'}"
                                    [value]="color.team">{{color.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div>
                        <button *ngIf="role.isInstructor()" fxFlex mat-button class="btn-purple float-right"
                            (click)="buyProduct()" style="margin-top: 30px;">
                            <mat-icon>attach_money </mat-icon>
                            Comprar
                        </button>
                    </div> <br> <br>
                    <div fxFlex="30" fxFlex.xs="0" style="text-align: justify;font-weight: bold; margin-top: 9%;">
                        <div style="font-size: 2em; font-weight: bold; margin-left: -12px; margin-bottom: 10px;">
                            Instrucciones del día</div>
                        <li *ngFor="let word of words; let i = index"> {{word}} </li>
                    </div>
                </div>
            </div>
        </div> -->
        <div *ngIf=" maxSlides != 0" style="width: 60%; margin-left: auto; margin-right: auto; text-align: center;">
            <div fxFlex="3" fxFlex.xs="0"></div>
            <div fxFlex="80" fxFlex.xs="100" fxLayout="column" fxLayoutAlign="center center">
                <div class="divCarrousel" style="width: 100%; height: 100%;">
                    <mat-carousel #matCarousel timings="50ms ease-in" [autoplay]="slides?.length <= 1" interval="50"
                        color="accent" maxWidth="auto" maintainAspectRatio="true" proportion="45"
                        slides="slides?.length" [loop]="true" [hideArrows]="true" [hideIndicators]="false"
                        [useKeyboard]="true" [useMouseWheel]="false" (change)="onChangePage($event)" orientation="ltr">
                        <mat-carousel-slide (change)="change()" #matCarouselSlide
                            *ngFor="let slide of slides; let i = index" [image]="slide.imageUrl"
                            overlayColor="#00000040" [hideOverlay]="true">
                        </mat-carousel-slide>
                    </mat-carousel>
                </div>
                <div style="width: 100%; margin-top: 20px;" *ngIf="slides?.length > 1">
                    <button mat-button class="btn-purple" (click)="matCarousel.previous()">
                        <mat-icon>arrow_back_ios </mat-icon>
                        Anterior
                    </button>
                    <button mat-button class="btn-purple" style="margin-left: 100px;" (click)="matCarousel.next()">
                        Siguiente
                        <mat-icon>arrow_forward_ios </mat-icon>
                    </button>
                </div>
            </div>
            <div fxFlex="3" fxFlex.xs="0"></div>
            <div fxFlex="30" fxFlex.xs="0" style="text-align: justify;font-weight: bold;">
                <div style="font-size: 2em; font-weight: bold; margin-left: -12px; margin-bottom: 10px;">Instrucciones
                    del día</div>
                <li *ngFor="let word of words; let i = index"> {{word}} </li>
            </div>
        </div>
    </div>
    <div style="width: 100%; padding-left: 5%; padding-right: 5%; margin-bottom: 30px;" fxFlex="100" fxFlex.xs="100"
        fxFlex.sm="100">
        <!--- ================= NOTAS ================== -->
        <textarea [ngStyle.xs]="{'width': '100%' }" [ngStyle.sm]="{'width': '100%' }" [(ngModel)]="notesInput"
            placeholder="NOTAS" style="color:#000; background:#ffc; min-height:10em; height: 450px; min-width:25%; display: block; box-shadow: 5px 5px 7px rgba(33,33,33,.7); float: left;padding: 4px;">
    </textarea>
    </div>
</div>