<app-header-top-sessions></app-header-top-sessions>
<!---->
<div 
  fxFlex="100"
  fxFlex.xs="98"
  fxLayout="column"
  [ngStyle.xs]="'background:none;'"
  >
  <div 
    style="margin-top: 190px;"
    fxFlex="100"
    >

    <!-- /.preloader -->
    <div class="page-wrapper">

    <!--Page Header Start-->
    <section class="page-header clearfix" style="background-image: url(assets/new/images/backgrounds/page-header-bg.jpg);">
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
                    <div class="page-header__wrapper clearfix">
                        <div class="page-header__menu">
                            <ul class="page-header__menu-list list-unstyled clearfix">
                                <li><a href="index.html">Inicio</a></li>
                                <li class="active">¿Quiénes somos?</li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <!--Page Header End-->


    <!--Start Teaching One-->
    <section class="teaching-one">
        <div class="container">
            <div class="row">
                <!--Start Teaching One Apply Form-->
                <div class="col-xl-6 col-lg-5">
                    <div class="teaching-one__apply-form">
                        <div class="title-box">
                            <h4>Regístrate</h4>
                        </div>
                        <div class="form-box">
                            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                                <div class="form-group">
                                    <input (input)="form.controls['name'].markAsTouched()" type="text" name="name" formControlName="name" placeholder="Nombre" required="">
                                </div>
                                <div class="form-group">
                                    <small *ngIf="form.controls['name'].hasError('required') && form.controls['name'].touched" 
                                    class="form-error-msg"> * Requerido</small>
                                </div>
                                <div class="form-group">
                                    <input (input)="form.controls['lastname'].markAsTouched()" type="text" name="lastname" formControlName="lastname" placeholder="Apellidos" required="">
                                </div>
                                <div class="form-group">
                                    <small *ngIf="form.controls['lastname'].hasError('required') && form.controls['lastname'].touched" 
                                    class="form-error-msg"> * Requerido</small>
                                </div>
                                <div class="form-group">
                                    <input (input)="form.controls['email'].markAsTouched()" type="email" name="email" formControlName="email" placeholder="Correo electrónico" required="">
                                </div>
                                <div class="form-group">
                                    <small *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched" 
                                    class="form-error-msg"> * Requerido</small>
                                    <small *ngIf="form.controls['email'].hasError('email') && form.controls['email'].touched" 
                                    class="form-error-msg"> * Correo electrónico no válido.</small>
                                </div>
                                <button class="registration-one__right-form-btn" type="submit"
                                        name="submit-form">
                                        <span class="thm-btn">Enviar</span>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                <!--End Teaching One Apply Form-->

                <!--Start Teaching One Content-->
                <div class="col-xl-6 col-lg-7">
                    <div class="teaching-one__content">
                        <h2 class="teaching-one__content-title">¿Quiénes somos?</h2>
                        <p class="teaching-one__content-text1">
                          My 4x4 Academy es una plataforma de aprendizaje interactivo digital, cuya especialidad es brindar cursos y talleres de vanguardia con base a la nueva economía de frente al futuro inmediato; es una iniciativa de Fundación Educación para Emprendedores AC, organización líder en el ecosistema de emprendimiento de América Latina. con más de {{yearsExperience}} años de trayectoria y 900,000 emprendedores capacitados en 35 paises.
                        </p>
                        <div class="teaching-one__content-img">
                            <img style="padding-top: 50px;" src="assets/new/images/resources/teaching-one-img1.jpg" alt=""/>
                        </div>
                    </div>
                </div>
                <!--End Teaching One Content-->
            </div>
        </div>
    </section>
    <!--End Teaching One-->

    <!--Start Meet Teachers One-->
    <section class="meet-teachers-one meet-teachers-one--teachers">
        <div class="container">
            <div class="row">
                <h2 class="teaching-one__content-title" style="text-align:center; padding-bottom:50px;">Nuestros Líderes</h2>
                <!--Start Single Meet Teachers One-->
                <div class="col-xl-12 col-lg-12">
                    <div class="meet-teachers-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                        <div class="meet-teachers-one__single-img">
                            <img src="assets/new/images/resources/meet-teachers-v1-img1.png" alt=""/>
                        </div>

                        <div class="meet-teachers-one__single-content">
                            <div class="meet-teachers-one__single-middle-content">
                                <div class="title">
                                    <h4><a href="#">Sam González</a></h4>
                                    <p>CEO My 4x4 Academy</p>
                                </div>
                                <p class="meet-teachers-one__single-content-text">Licenciado en Ciencias de la Comunicación egresado del Tec de Monterrey Campus Estado de México; Maestría en Comunicación Corporativa – Universidad Anáhuac del Norte (Estado de México) ; Maestría en Administración de Empresas Multinacionales – Universidad Adolfo Ibañez / ESADE, con doble titulación en USA y España; Maestría en Mercadotecnia Digital y Comercio Electrónico – IEBS – Barcelona, Es Fundador y Presidente Ejecutivo de Fundación Educación para Emprendedores AC, institución especializada en modelos para el desarrollo de emprendedores. Desde 1999, ha generado e implementado programas, foros, eventos e iniciativas para Gobiernos, Corporativos, Micro Financieras, Agencias Internacionales, Instituciones Educativas y Organizaciones de la Sociedad Civil, en 4 continentes. Ganador en el 2021 a las palmas de oro del circulo nacional de periodistas en México por sus 25 años de trayectoría como emprendedor social. En 2022 fue galardonado por la Revista Red de Negocios como el coach empresarial más influyente de México. Autor del libro 4x4 Emprendedores Todo Terreno y actual catedrático en el Tecnológico de Monterrey. </p>
                            </div>

                            <div class="meet-teachers-one__single-bottom-content">
                                <div class="meet-teachers-one__single-content-courses-box">
                                    <div class="text">
                                        <p>Ponte en contacto:</p>
                                    </div>
                                    <div class="social-icon">
                                        <ul class="list-unstyled">
                                            <li><a href="https://www.linkedin.com/company/fundacion-e/" target="_blank"><i class="fab fa-linkedin"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--End Single Meet Teachers One-->

                <!--Start Single Meet Teachers One-->
                <div class="col-xl-12 col-lg-12">
                    <div class="meet-teachers-one__single wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                        <div class="meet-teachers-one__single-img">
                            <img src="assets/new/images/resources/meet-teachers-v1-img2.png" alt=""/>
                        </div>

                        <div class="meet-teachers-one__single-content">
                            <div class="meet-teachers-one__single-middle-content">
                                <div class="title">
                                    <h4><a href="#">Grace Durán</a></h4>
                                    <p>COO My 4x4 Academy </p>
                                </div>
                                <p class="meet-teachers-one__single-content-text">Emprendedora social, coach en desarrollo personal y de equipos  certificada por la escuela europea de coaching (International Coaching Federation, Facilitador  vivencial en emprendimiento certificado por Making cents International, Diplomada en educación para el emprendimiento por la  escuela de negocios de la Universidad de Chile. Universidad  del Salvador en medicina  hasta cuarto año. Directora  general de Fundación E desde el año 2007 a la fecha. Directora ejecutiva de 4x4 emprendedores todo terreno spa desde el año 2019 a la fecha. Socia fundadora  y CEO de “Senioripity el club” desde agosto 2020 a la fecha.</p>
                            </div>

                            <div class="meet-teachers-one__single-bottom-content">
                                <div class="meet-teachers-one__single-content-courses-box">
                                    <div class="text">
                                        <p>Ponte en contacto:</p>
                                    </div>
                                    <div class="social-icon">
                                        <ul class="list-unstyled">
                                            <li><a href="https://www.linkedin.com/company/fundacion-e/" target="_blank"><i class="fab fa-linkedin"></i></a></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--End Single Meet Teachers One-->
            </div>
        </div>
    </section>
    <!--End Meet Teachers One-->

    </div><!-- /.page-wrapper -->

    <div class="mobile-nav__wrapper">
        <div class="mobile-nav__overlay mobile-nav__toggler"></div>
        <!-- /.mobile-nav__overlay -->
        <div class="mobile-nav__content">
            <span class="mobile-nav__close mobile-nav__toggler"><i class="fa fa-times"></i></span>

            <div class="logo-box">
                <a href="index.html" aria-label="logo image"><img src="assets/images/resources/mobilemenu_logo.png" width="155" alt="" /></a>
            </div>
            <!-- /.logo-box -->
            <div class="mobile-nav__container"></div>
            <!-- /.mobile-nav__container -->

            <ul class="mobile-nav__contact list-unstyled">
                <li>
                    <i class="icon-phone-call"></i>
                    <a a href="mailto:platicame@my4x4academy.com">platicame@my4x4academy.com</a>
                </li>
                <li>
                    <i class="icon-letter"></i>
                    <a href="tel:666-888-0000">666 888 0000</a>
                </li>
            </ul><!-- /.mobile-nav__contact -->
            <div class="mobile-nav__top">
                <div class="mobile-nav__social">
                    <li><a href="https://www.facebook.com/4x4Emprendedores/" target="_blank"><i class="fab fa-facebook"></i></a></li>
                    <li><a href="https://www.instagram.com/4x4emprendedores/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    <li><a href="https://www.youtube.com/channel/UCK4nuGlFDZhywzxZdWfNoOw" target="_blank"><i class="fab fa-youtube"></i></a></li>
                    <li><a href="https://www.linkedin.com/company/fundacion-e/" target="_blank"><i class="fab fa-linkedin"></i></a></li>
                </div><!-- /.mobile-nav__social -->
            </div><!-- /.mobile-nav__top -->
        </div>
        <!-- /.mobile-nav__content -->
    </div>
    <a (click)="gotoTop()" data-target="html" class="scroll-to-target scroll-to-top"><i class="fa fa-angle-up"></i></a>
  </div>

  <div 
    fxFlex="100"
    >
    <app-footer></app-footer>
  </div>
</div>
