
<!-- GAME -->
<style>
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button {
        opacity: 1;
    }
</style>

<div  fxLayoutAlign="center center" fxLayout="column" fxLayoutAlign="space-between center" style="position: relative;">

    <div fxFlex="100" fxLayout="row" fxLayout.xs="column" style="width: 100%;">
        <div id="dinero-equipo" fxLayout="column" fxFlex="20" fxFlex.xs="100" style="margin-left: 20px;">
            <h4 style="font-weight: bold;">DINERO EN EQUIPO</h4>
            <div style="width: 84%; height: 50px; border-radius: 15px 15px 15px 0; background-color: #7ecb20;min-width: 150px; align-items: center;display: flex;justify-content: center; color: white; padding: 10px; box-shadow: 5px 5px 5px 2px #cecece; margin-right: 20px;">
                <img style="margin-right:10px;" src="assets/images/game/leekel_symbol_green.png">
                <h4 style="font-weight: bold; margin: 0px;">{{ this.totalLeekels }}</h4>
            </div>
            <div *ngIf="role.isInstructor() == false" fxLayout="row" style="width: 25%; min-width: 120px; background-color: #7ecb20; color: white; border-radius: 0 0 15px 15px;">
                <table style="float: left; text-align: center; font-weight: bold; min-width: 70px;">
                    <tr>
                        <td style="border: 1px white solid;">I</td>
                        <td style="border: 1px white solid;"><img style="width: 10px; margin-left:5px; float: left;" src="assets/images/game/leekel_symbol_green.png">{{ this.inversion }}</td>
                    </tr>
                    <tr>
                        <td style="border: 1px white solid;">G</td>
                        <td style="border: 1px white solid;"><img style="width: 10px; margin-left:5px; float: left;" src="assets/images/game/leekel_symbol_green.png">{{ this.gasto }}</td>
                    </tr>
                    <tr>
                        <td style="border: 1px white solid;">A</td>
                        <td style="border: 1px white solid;"><img style="width: 10px; margin-left:5px; float: left;" src="assets/images/game/leekel_symbol_green.png">{{ this.ahorro }}</td>
                    </tr>
                </table>
            </div>
            <ng-container *ngIf="role.isInstructor()" >
                <div *ngFor="let eq of montosXEquipo" fxLayout="row" style="width: 80%; background-color: #7ecb20; color: white; border-radius: 0 0 15px 15px; box-shadow: 5px 5px 5px 2px #cecece;">
                    <table style="float: left; text-align: center; font-weight: bold;">
                        <tr>
                            <td style="border: 1px white solid;">I</td>
                            <td style="border: 1px white solid;">
                                $<input #_investment style="background-color: transparent; color: white; font-weight: bold;" type="number" [value]="eq.inversion">
                            </td>
                            <td style="border: 1px white solid;"></td>
                        </tr>
                        <tr>
                            <td style="border: 1px white solid;">G</td>
                            <td style="border: 1px white solid;">
                                $<input #_spend style="background-color: transparent; color: white; font-weight: bold;" type="number" [value]="eq.gasto">
                            </td>
                            <td style="border: 1px white solid;"> Team {{eq.team}} </td>
                        </tr>
                        <tr>
                            <td style="border: 1px white solid;">A</td>
                            <td style="border: 1px white solid;">
                                $<input #_saving style="background-color: transparent; color: white; font-weight: bold;" type="number" [value]="eq.ahorro">
                            </td>
                            <td style="border: 1px white solid;">
                                <button type="button" mat-button (click)="confirmUpdateLeekelsByTeam(eq,_investment.value,_spend.value,_saving.value)">
                                    <small>Actualizar</small>
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
            </ng-container>
        </div>
        <div id="calendar-game" fxFlex.xs="100" fxFlex style="text-align: center;">
            <mat-grid-list cols="3" rows="2" rowHeight="40px">
                <mat-grid-tile (click)="openCalendarInfoDialog(1)" style="border-radius: 15px 0 0 15px; box-shadow: 5px 5px 5px 2px #cecece;" [colspan]="1" [rowspan]="2" class="bg-purple text-light cursor-pointer">
                    <h2>Semana  {{ this.week }} <!--{{calendarDay?.week}}--></h2>
                </mat-grid-tile>
                <mat-grid-tile (click)="openCalendarInfoDialog(1)" [colspan]="1" [rowspan]="2" class="bg-purple text-light cursor-pointer" style="box-shadow: 5px 5px 5px 2px #cecece;">
                    <div
                        style="display: flex; flex-direction: row; justify-content: space-evenly; align-items: center;width: 80%; ">
                        <mat-icon aria-hidden="false" aria-label="Example home icon">insert_invitation</mat-icon>
                        <p style="margin-bottom: 0rem; font-size:2em;">{{dayName(calendarDay?.id)}}</p>
                        <p style="margin-bottom: 0rem; font-size:2em;">{{calendarDay?.id}}</p>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile style="border-radius: 0 15px 15px 0; box-shadow: 5px 5px 5px 2px #cecece;" [colspan]="1" [rowspan]="2">
                    <div style="height: 100%; width: 100%;">
                        <mat-grid-list cols="2" rows="2" rowHeight="20px">
                            <mat-grid-tile [colspan]="2" [rowspan]="2" class="bg-purple text-light" style="box-shadow: 5px 5px 5px 2px #cecece;">
                                {{calendarDay?.objective}}
                            </mat-grid-tile>
                            <mat-grid-tile [colspan]="2" [rowspan]="2" class="bg-purple text-light">
                                {{calendarDay?.summary}}
                            </mat-grid-tile>
                        </mat-grid-list>
                    </div>
                </mat-grid-tile>
            </mat-grid-list>
        </div>
        <div style="float: left; padding-top: 1%;" *ngIf="role.isInstructor()">
            <button mat-icon-button aria-label="Siguiente dia" (click)="changeDay()">
                <mat-icon style="font-size: 50px;">arrow_right</mat-icon>
            </button>
        </div>
        <div id="options-calendar" fxLayout="column" fxFlex.xs="100" fxFlex="20" fxLayoutAlign="start end" [ngStyle.sm]="{'height': '120px'}" >
            <div [ngStyle.xs]="{'width': '100%','margin-top': '2%','margin-right': '0px'}" style="float: right; bottom: 0px; height: fit-content;" fxLayout="row" fxLayout.md="column" fxLayout.sm="column" fxLayout.xs="column" fxFlex.xs="100" fxFlex.sm="100">
                <div [ngStyle.xs]="{'margin-right': '0px','margin-top': '1%'}" [ngStyle.sm]="{'margin-right': '0px','margin-top': '1%'}" [ngStyle.md]="{'margin-right': '0px','margin-top': '1%'}" id="buttom-zoom" fxFlex fxLayoutAlign="end end" fxLayout="row" style="margin-right: 10px; height: 55px;" fxFlex.xs="100" fxFlex.sm="100">
                    <a fxFlex="100px" fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="100" [href]="linkMeetingZoom ? linkMeetingZoom : null" target="_blank" class="bg-purple text-light" style="border-radius: 10px 10px 10px 10px; box-shadow: 5px 5px 5px 2px #cecece; height: 50px; display: flex; align-items: center; justify-content: center;">
                        <img src="assets/images/game/zoom_logo.png" style="width: 25px; max-width: 25px; margin-right: 8px;">
                        <span style="font-weight: bold;"> ZOOM</span>
                    </a>
                </div>
                <div id="button-update-session" fxFlex fxLayoutAlign="end end" fxLayout="row" style="height: 55px; cursor: pointer;" fxFlex.xs="100" fxFlex.sm="100">
                    <div fxFlex.xs="100" fxFlex.sm="100" fxFlex="180px" (click)="getCalendarDay()" class="bg-purple text-light" style="border-radius: 10px 10px 10px 10px; box-shadow: 5px 5px 5px 2px #cecece; height: 50px; display: flex; align-items: center; justify-content: center;">
                        <mat-icon style="margin-right: 8px;">autorenew</mat-icon>
                        <span fxShow fxHide.xs fxHide.sm> Actualizar sesión</span>
                        <span fxHide fxShow.xs fxShow.sm> Actualizar</span>
                    </div>
                </div>
            </div>
            <div id="chronometer" style="height: 55px; float: right; width: 90%; text-align: center; background-color: #7ecb20; color: white; margin-left: 10%; margin-top: 5px; border-radius: 10px 0 0 10px; padding: 5px; font-size: 1.5rem; box-shadow: 5px 5px 5px 2px #cecece; display: flex; align-items: center; justify-content: center;">
                <mat-icon style="width: 30%;">schedule</mat-icon>
                <label style="width: 70%; font-weight: bold;margin-bottom: 0rem;">{{ this.time }}</label>
            </div>
            <!-- <div style="height: 55px; float: right; width: 80%; text-align: center; background-color: #7ecb20; color: white; margin-left: 20%; margin-top: 5px; border-radius: 10px 0 0 10px; padding: 5px; font-size: 1.5rem; box-shadow: 5px 5px 5px 2px #cecece;">
                <mat-icon style="width: 30%;">users</mat-icon>
                <label style="width: 70%; font-weight: bold;margin-bottom: 0rem;">{{calendarDay?.subject}}</label>
            </div> -->
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center start" id="activities-day" [ngStyle.xs]="{'margin-bottom': '30px'}" [ngStyle.sm]="{'margin-bottom': '30px'}" [ngStyle.md]="{'margin-bottom': '30px'}" style="min-height: 400px; height: fit-content; width: 100%; margin-bottom: 140px; z-index: 1;">
        <div *ngIf="calendarDay?.id==1" style="width: 100%; margin-left: auto; margin-right: auto; text-align: center; min-height: 550px;" >
            
            <!-- VIDEO INICIAL -->
            <div *ngIf = "this.step == 0" style="width: 100%;" fxLayout="column" fxLayoutAlign.xs="stretch center" fxLayoutAlign.sm="stretch center" fxLayoutAlign="space-around center"  fxLayout.xs="column" fxLayout.sm="column">
                <video controlsList="nodownload" [ngStyle.xs]="{'width': '90%','margin-top': '2%'}" [ngStyle.sm]="{'width': '90%','margin-top': '2%'}" controls preload="auto" fxFlex="35" style="width: 35%; border-radius:20px; box-shadow: 5px 5px 5px 2px #cecece;">
                    <source src="assets/videos/introduccion.mp4"/>
                </video>
                <div style="margin-top: 10px;">
                    <button mat-button class="btn-purple" style="padding: 5px; font-size: 1pem; border: none;" (click)="nextStep()">Siguiente</button>
                </div>
            </div>
            <!-- Video extra -->
            <div *ngIf = "this.step == 0.5" style="width: 100%;"  fxLayout="column" fxLayoutAlign.xs="stretch center" fxLayoutAlign.sm="stretch center" fxLayoutAlign="space-around center"  fxLayout.xs="column" fxLayout.sm="column">
                <video controlsList="nodownload" [ngStyle.xs]="{'width': '90%','margin-top': '2%'}" [ngStyle.sm]="{'width': '90%','margin-top': '2%'}" controls preload="auto"  style="width: 35%; border-radius:20px; box-shadow: 5px 5px 5px 2px #cecece;">
                    <source src="assets/videos/instrucciones.mp4"/>
                </video>
                <div style="margin-top: 10px;">
                    <button mat-button class="btn-purple" style="padding: 5px; font-size: 1pem; border: none; margin-right: 5px;" (click)="previousStep()">Anterior</button>
                    <button mat-button class="btn-purple" style="padding: 5px; font-size: 1pem; border: none;" (click)="nextStep()">Siguiente</button>
                </div>
            </div>
            <!-- CONTRATO DE LEEKELS -->
            <div *ngIf = "this.step <= 2 && this.step > 0 && this.step != 0.5 " style="width: 100%;">
                <div fxFlex="20" fxFlex.xs="0" fxFlex.sm="0"></div>
                <div fxFlex="60" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column" fxLayoutAlign="center center">
                    <div style="height: 0px" [ngStyle.xs]="{'height': '30px'}"></div>
                    <img [src]="leekelsImage()" style="width: 80%; max-width: 752px; border-radius:20px; box-shadow: 5px 5px 5px 2px #cecece;">
                    <div style="margin-top: 20px;">
                        <button mat-button class="btn-purple" style="padding: 5px; font-size: 1pem; border: none; margin-right: 5px;" (click)="previousStep()">Anterior</button>
                        <button mat-button class="btn-purple" style="padding: 5px; font-size: 1pem; border: none;" *ngIf="this.step == 1 && myInfoUserSession.leader" (click)="nextStep()">Firmar</button>
                        <button *ngIf="((isSignature && !myInfoUserSession.leader) || myInfoUserSession.leader) && this.step == 2" mat-button class="btn-purple" style="padding: 5px; font-size: 1pem; border: none;" (click)="nextStep()">Siguiente</button>
                    </div>
                </div>
                <div fxFlex="20" fxFlex.xs="0" fxFlex.sm="0"></div>
            </div>
            
            <div *ngIf = "this.step == 3" fxLayout.xs="column" fxLayout.sm="column" fxLayout="row" fxLayoutAlign="end center" style="width: 100%;">
                <div fxFlex="20" fxFlex.xs="0" fxFlex.sm="0"></div>
                <div fxFlex="60" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column" fxLayoutAlign="center center">
                    <div style="height: 0px" [ngStyle.xs]="{'height': '30px'}"></div>
                    <!-- <h2 style="margin-bottom:20px; margin-left:23%;">Caso: {{ this.charName }}</h2> -->
                    <img style="width: 80%; max-width: 752px; border-radius:20px; box-shadow: 5px 5px 5px 2px #cecece;" [src]="this.charUrl">
                    <div fxLayout="row" fxLayoutAlign="center center" style="margin-top: 20px;">
                        <button mat-button class="btn-purple" style="padding: 5px; font-size: 1pem; border: none; margin-right: 5px;" (click)="previousStep()">Anterior</button>
                        <button mat-button class="btn-purple" style="padding: 5px; font-size: 1pem; border: none;" (click)="nextStep()">Siguiente</button>
                    </div>
                </div>
                <div fxFlex="20" fxShow fxHide.xs fxHide.sm fxFlex.sm="0" fxFlex.xs="0" fxLayout="column" fxLayoutAlign="center end">
                    <div id="char1" (click)="changeCharVideo('assets/images/game/personajes/11-Ricardo-Riqueza.jpg', 1)" [ngClass]="{'casosDayCeroSelected':(charNumb==1),'casosDayCeroUnselected':(charNumb!=1)}">
                        <label style="width: 70%; font-weight: bold;margin-bottom: 0rem;">Ricardo Riqueza</label>
                    </div>
                    <div id="char2" (click)="changeCharVideo('assets/images/game/personajes/13-Alberto-el-Ahorrador.jpg', 2)" [ngClass]="{'casosDayCeroSelected':(charNumb==2),'casosDayCeroUnselected':(charNumb!=2)}">
                        <label style="width: 70%; font-weight: bold;margin-bottom: 0rem;">Alberto El Ahorrador</label>
                    </div>
                    <div id="char3" (click)="changeCharVideo('assets/images/game/personajes/12-Vanessa-Voraz.jpg', 3)" [ngClass]="{'casosDayCeroSelected':(charNumb==3),'casosDayCeroUnselected':(charNumb!=3)}">
                        <label style="width: 70%; font-weight: bold;margin-bottom: 0rem;">Vanesa Voraz</label>
                    </div>
                    <div id="char4" (click)="changeCharVideo('assets/images/game/personajes/14-Susana-Sensata.jpg', 4)" [ngClass]="{'casosDayCeroSelected':(charNumb==4),'casosDayCeroUnselected':(charNumb!=4)}">
                        <label style="width: 70%; font-weight: bold;margin-bottom: 0rem;">Susana Sensata</label>
                    </div>
                </div>
            </div>
            <div *ngIf = "this.step == 4" style="width: 100%;">
                <h2 style="margin: 7% 0 7% 0">Espera a que el facilitador cambie al día siguiente...</h2>
                <button mat-button class="btn-purple" style="padding: 5px; font-size: 1pem; border: none; margin-right: 5px;" (click)="previousStep()">Anterior</button>
            </div>
        </div>
        <div *ngIf="(dayIn(calendarDay?.id,mondayDays))" style="width: 100%;">
            <div fxFlex="20" fxFlex.xs="0" fxFlex.sm="0"></div>
            <div fxFlex="60" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column" fxLayoutAlign="center center">
                <!-- <div [ngStyle.xs]="{'font-size': '10px' }" [ngStyle.sm]="{'font-size': '20px' }" style="position: relative; top: 56%; font-size:3em; right: -26%; font-size: xx-large; height: 20%;">{{ this.materiaPrima }}</div> -->
                <img src="assets/images/game/04 Proveedores Luis.jpg" style="width: 80%; max-width: 752px; border-radius: 20px; box-shadow: 5px 5px 5px 2px #cecece; margin-bottom: 25px;">
            </div>
            <div fxFlex="20" fxFlex.xs="0" fxFlex.sm="0"></div>
        </div>
        <div *ngIf="(dayIn(calendarDay?.id,tuesdayDays))" style="width: 100%; text-align: center;" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="space-between center" >
            <div fxFlex="20" fxFlex.xs="0"></div>
            <div fxFlex="60" fxFlex.xs="100" fxLayout="column" fxLayoutAlign="center center">
                <img src="assets/images/game/fabrica - martes.jpg" style="width: 80%; max-width: 752px; border-radius: 20px; box-shadow: 5px 5px 5px 2px #cecece;">
            </div>
            <div fxFlex="20" fxFlex.sm="100" fxFlex.xs="100" *ngIf="this.week >= 2" fxLayout="row" fxLayoutAlign="end center">
                <div style="max-width: 250px;" fxLayoutAlign="space-between end" fxLayoutAlign.sm="center center" fxLayoutAlign.xs="center center" fxLayout="column">
                    <!--ruleta de la vida-->
                    <ng-container *ngIf="(calendarDay?.id == 20 || calendarDay?.id == 27)" style="margin-bottom: 20px; width: 100%;">
                        <div *ngIf="myInfoUserSession.leader" fxLayout="column" style="width: 100%; margin-bottom: 30px;">
                            <mat-label>Tarjetas de la Vida</mat-label><br>
                            <button style="width: 250px;" type="button" class="btn-purple" mat-button (click)="setShowCards()" [disabled]="!showStopButton">Elegir Tarjeta</button>
                        </div>
                    </ng-container>
                    <!-- <div>
                        <div fxFlex="350px" (click)="openCalendarInfoDialog(2)" class="bg-purple text-light" style="padding: 10px; border-radius: 10px 10px 10px 10px; box-shadow: 5px 5px 5px 2px #cecece;">
                            <mat-icon>smart_display</mat-icon>
                            <label style="font-size: 1.5rem; display:inline-block; vertical-align: -4px; padding-left:10px;">Video</label>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <div *ngIf="(dayIn(calendarDay?.id,wednesdayDays))" fxLayout="row" fxLayoutAlign="center space-between" fxLayout.xs="column" fxLayoutAlign.xs="center center" fxLayout.sm="column" fxLayoutAlign.sm="center center" style="width: 100%;">
            <div fxFlex="20" fxFlex.xs="0"></div>
            <div fxFlex="60"fxLayoutAlign="center center">
                <img style="width: 80%; max-width: 752px;" src="assets/images/game/05 La Empresa de Maria.jpg">
            </div>
            <div fxFlex="20" fxFlex.sm="80" fxFlex.xs="80" fxLayout="row" fxLayoutAlign="end center">
                <div *ngIf="(myInfoUserSession.leader && calendarDay?.id > 11 && this.week >= 3)" fxLayout="column">
                    <label>Sam el Mayorista</label>
                    <button style="width: 250px;"
                        type="button"
                        mat-button 
                        class="btn-purple"
                        (click)="spinRoulette()"
                        [disabled]="disableRouletteButton">
                        <mat-label>Girar</mat-label>
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="(dayIn(calendarDay?.id,thursdayDays))" style="width: 100%; margin-left: auto; margin-right: auto; text-align: center;" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="space-between center" >
            <div fxFlex="20" fxFlex.xs="0"></div>
            <div fxFlex="60" fxFlex.xs="100" fxLayout="column" fxLayoutAlign="center center">
                <img src="assets/images/game/03 Banco de Emprendedores a Empresarios.jpg" style="width: 80%; max-width: 752px; border-radius: 20px; box-shadow: 5px 5px 5px 2px #cecece;">
            </div>
            <div fxFlex="20" fxFlex.xs="100" fxLayout="row" fxLayoutAlign="end center">
                <div *ngIf="calendarDay?.id < 29" style="max-width: 350px;">
                    <button style="width: 250px;" type="button" mat-button class="btn-purple" (click)="openCalendarInfoDialog(2)">
                        <mat-icon>smart_display</mat-icon>
                        <mat-label style="padding-left:10px;">Video</mat-label>
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="dayIn(calendarDay?.id,saturdayDays)" fxLayoutAlign="space-between center" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" style="width: 100%; margin-left: auto; margin-right: auto; text-align: center;">
            <div fxFlex="10" fxFlex.xs="5" fxFlex.sm="5"></div>
            <div  [ngStyle.xs]="{'margin-top': '7%'}" fxFlex="60" fxFlex.xs="90" fxFlex.sm="90" style="height: 400px !important; padding:0; margin:0; box-sizing:border-box; width:100%; overflow-y: scroll; margin-bottom: 25px;">
                <div *ngFor="let product of products" style="display:inline-block; width:95px;">
                    <div fxLayoutAlign="center center" fxLayout="column" [ngStyle]="{'background-color': product?.isSelected==1 ? '#c8c8c8' : 'white', 'color': 'black' }" (click)="this.role.isClient() == false ? selectedProduct(product) : return; ">
                        <img [src]="product?.image_url" alt="" style="height: 70px; width: 70px;">
                        <label>${{product?.price}}</label>
                        <h6>{{product?.virtual_product}}</h6>
                    </div>
                </div>
            </div>
            <div fxFlex="10" fxFlex.xs="5" fxFlex.sm="5" fxLayout="column" fxLayoutAlign="center end">
                <button style="width: 250px;"
                type="button"
                mat-button 
                class="btn-purple"
                (click)="showProductsBoughtDialog()"
                *ngIf="this.role.isClient()">
                <mat-label>Productos Comprados</mat-label>
                </button>
                <!-- ================ LISTA DE ALUMNOS INSTRUCTOR ================ -->
                <div style="text-align: center; color: white; background-color: #7ecb20; border-radius: 10px;" *ngIf="role.isInstructor()">
                    <mat-form-field appearance="outline">
                        <mat-label>Seleccione un equipo: </mat-label>
                        <mat-select matInput [(ngModel)]="teamSelected">
                            <mat-option *ngFor="let color of colors" [value]="color.team">{{color.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <button *ngIf="role.isInstructor()" fxFlex mat-button class="btn-purple float-right" (click)="buyProduct()">
                    <mat-icon>attach_money </mat-icon>
                    Comprar
                </button>
            </div>
        </div>
        <div *ngIf="(dayIn(calendarDay?.id,sundayDays))" style="width: 100%; margin-left: auto; margin-right: auto; text-align: center;">
            <div fxFlex="20" fxFlex.xs="0" fxFlex.sm="0"></div>
            <div fxFlex="60" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column" fxLayoutAlign="center center">
                <h2 style="margin-bottom:20px;">DESCANSO</h2>
                <img src="assets/images/game/descanso - dom.jpg" style="width: 80%; max-width: 752px; border-radius: 20px; box-shadow: 5px 5px 5px 2px #cecece;">
            </div>
            <div fxFlex="20" fxFlex.xs="0" fxFlex.sm="0"></div>
        </div>
        <div *ngIf="calendarDay?.id == 30" style="width: 100%; margin-left: auto; margin-right: auto; text-align: center;" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
            <div fxFlex="20" fxFlex.xs="0"></div>
            <div fxFlex="60" fxFlex.xs="100" fxLayout="column" fxLayoutAlign="center center">
                <h2 style="margin-bottom:20px;">CONCLUSIONES</h2>
                <img src="assets/images/game/conclusiones.jpeg" style="width: 80%; max-width: 752px; border-radius: 20px; box-shadow: 5px 5px 5px 2px #cecece;">
            </div>
            <div fxFlex="20" fxFlex.xs="100" fxLayoutAlign="end center">
                <div style="max-width: 300px;">
                    <button style="width: 250px;" type="button" mat-button class="btn-purple" (click)="openCalendarInfoDialog(2)">
                        <mat-icon>smart_display</mat-icon>
                        <mat-label style="padding-left:10px;">Video</mat-label>
                    </button>
                </div>
            </div>
        </div>
        <br fxShow fxHide.xs fxHide.sm><br fxShow fxHide.xs fxHide.sm><br fxShow fxHide.xs fxHide.sm>
        <div fxLayout="column" style="width: 100%; height: 70%; max-height: 300px; margin-top: 0px;" fxLayoutAlign="center center" *ngIf="role.isClient() && (this.step >= 3 || calendarDay?.id > 1) &&  (dayIn(calendarDay?.id,fridayDays)  &&   calendarDay?.id < 30  || dayIn(calendarDay?.id,saturdayDays) || dayIn(calendarDay?.id,mondayDays))">
            <form [formGroup]="montosForm" fxFlex="60" fxLayoutAlign="space-between center" fxLayout="row" fxLayout.xs="column" fxFlex.xs="80" fxFlex.sm="80">
                <div fxLayoutAlign="center center" fxLayout="column" fxFlex="33">
                    <p style="font-weight: bold;">INVERSIÓN</p>
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label style="color: #000;">Monto</mat-label>
                        <input formControlName="inversion" matInput type="number" class="example-right-align" placeholder="0" min="0" oninput="(validity.valid)||(value='');">
                        <span *ngIf="!this.myInfoUserSession?.leader" style="color: #F0F0F0;" matPrefix>$&nbsp;</span>
                        <span *ngIf="this.myInfoUserSession?.leader" style="color: #000;" matPrefix>$&nbsp;</span>
                        <!-- <span matSuffix>.00</span> -->
                    </mat-form-field>
                </div>
                <div fxLayoutAlign="center center" fxLayout="column" fxFlex="33">
                    <p style="font-weight: bold;"> GASTO</p>
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label style="color: #000;">Monto</mat-label>
                        <input formControlName="gasto" matInput type="number" class="example-right-align" placeholder="0" min="0" oninput="(validity.valid)||(value='');">
                        <span *ngIf="!this.myInfoUserSession?.leader" style="color: #F0F0F0;" matPrefix>$&nbsp;</span>
                        <span *ngIf="this.myInfoUserSession?.leader" style="color: #000;" matPrefix>$&nbsp;</span>
                        <!-- <span matSuffix>.00</span> -->
                    </mat-form-field> 
                </div>
                <div fxLayoutAlign="center center" fxLayout="column" fxFlex="33">
                    <p style="font-weight: bold;">AHORRO</p>
                    <mat-form-field appearance="fill" floatLabel="always">
                        <mat-label style="color: #000;">Monto</mat-label>
                        <input formControlName="ahorro" matInput type="number" class="example-right-align" placeholder="0" min="0" oninput="(validity.valid)||(value='');" >
                        <span *ngIf="!this.myInfoUserSession?.leader" style="color: #F0F0F0;" matPrefix>$&nbsp;</span>
                        <span *ngIf="this.myInfoUserSession?.leader" style="color: #000;" matPrefix>$&nbsp;</span>
                        <!-- <span matSuffix>.00</span> -->
                    </mat-form-field>            
                </div>
                
            </form>
            <button *ngIf="this.myInfoUserSession?.leader" mat-flat-button color="accent" [disabled]="!montosForm.valid || checkTotales()" (click)="OnGuardarCambioMontos()" >GUARDAR</button>
            <!-- <button mat-flat-button color="accent"  (click)="OnGuardarCambioMontos()" >GUARDAR</button> -->
        </div>
        <br fxShow fxHide.xs fxHide.sm><br fxShow fxHide.xs fxHide.sm>
    </div>
    <!---->
    <div fxFlex="100" fxLayoutAlign="center end" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" [ngStyle.xs]="{'position': 'relative'}" [ngStyle.sm]="{'position': 'relative'}" [ngStyle.md]="{'position': 'relative'}" style="width: 100%; position: absolute; bottom: 0px;">
        <div fxLayout="column" fxLayoutAlign="space-between start" fxLayout.xs="row" fxLayout.sm="row" fxFlex="40" [ngStyle.xs]="{'width': '100%'}" [ngStyle.sm]="{'width': '100%'}">
            <div fxFlex.xs fxFlex.sm *ngIf="((calendarDay?.id == 20 ||  calendarDay?.id == 27))" style="float: left; margin-left: 20px; font-weight: bold;max-width: 50%; min-width: 270px; width: 320px;" >
                <div class="bg-purple text-light" style="padding: 10px; border-radius: 10px 10px 10px 10px; display: flex; align-items: center; height: 65px;">
                    <label style="font-size: 1.5rem; padding-left: 10px;">Tarjeta Elegida</label>
                </div>
                <div class="text-light" style="background-color: darkmagenta; border-radius: 5px 5px 5px 5px; padding: 5px 10px; margin-top: 5px;">
                    <!-- <img style="width: 260px;height: 120px;" [src]="currentCard?.cardImage"> -->
                    <p style="text-align: justify;">{{currentCard?.text}}</p>
                </div>
            </div>
            <div fxFlex.xs fxFlex.sm *ngIf="(dayIn(calendarDay?.id,wednesdayDays) && this.week >= 3)" style="float: left; margin-left: 20px; font-weight: bold;max-width: 50%; width: 320px; min-width: 270px;" >
                <div class="bg-purple text-light" style="padding: 10px; border-radius: 10px 10px 10px 10px; box-shadow: 5px 5px 5px 2px #cecece; display: flex; align-items: center; height: 65px;">
                    <mat-icon>redeem</mat-icon>
                    <span style="font-size: 1.5rem; padding-left: 10px;">Caso 'Sam el mayorista'</span>
                </div>
                <div class="text-light" style="background-color: darkmagenta; border-radius: 5px 5px 5px 5px; padding: 5px 10px; margin-top:5px;">
                    <p style="text-align: justify;">{{ (roulettePrize?.week == week ) ? roulettePrize?.roulettePrize : '' }}</p>
                </div>
            </div>
            <div fxFlex.xs fxFlex.sm style="bottom: 120px; float: left; margin-left: 20px; font-weight: bold; max-width: 50%; min-width: 270px; width: 320px; margin-top: 5px;" [ngStyle.xs]="{'width': '54%','max-width': '100%'}" >
                <div id="raw-material" class="bg-purple text-light" style="padding: 10px; border-radius: 10px 10px 10px 10px; box-shadow: 5px 5px 5px 2px #cecece; display: flex; align-items: center; height: 65px;">
                    <mat-icon>precision_manufacturing</mat-icon>
                    <span style="font-size: 1.5rem; padding-left: 10px;">MATERIA PRIMA</span>
                </div>
                <div class="text-light" style="background-color: darkmagenta; border-radius: 5px 5px 5px 5px; padding: 5px 10px; margin-top:5px; box-shadow: 5px 5px 5px 2px #cecece;">
                    {{ this.materiaPrima }}
                </div>
            </div>
            <div fxFlex.xs fxFlex.sm style="float: left; margin-left: 20px; font-weight: bold; max-width: 50%; min-width: 270px; margin-top: 5px; width: 320px" >
                <div id="bank" class="text-light" style="padding: 10px; border-radius: 10px 10px 10px 10px; box-shadow: 5px 5px 5px 2px #cecece; background-color: #5662f6; display: flex; align-items: center; height: 65px;">
                    <mat-icon>food_bank</mat-icon>
                    <span style="font-size: 1.5rem; padding-left: 10px;">BANCO</span>
                </div>
                <div class="text-light" style="background-color: #5662f6; border-radius: 5px 5px 5px 5px; padding: 5px 10px; margin-top:5px; box-shadow: 5px 5px 5px 2px #cecece; display: flex; align-items: center; margin-bottom: 5px;">
                    <img style="width: 10px; margin-right: 10px;" src="assets/images/game/leekels_symbol_purple.png">
                    <span>{{ this.deuda }}</span>
                </div>
            </div>
        </div>
        <!--style="position: relative; bottom: 0; float: right; right: 0; bottom: calc(0 - 40px); font-size: 1.5rem; font-weight: bold; padding: 10px; border-radius: 10px 0 0 0; margin-top: 2%; width: 40%;"-->
        <div class="text-light" fxFlex="60" fxFlex.xs="100" fxFlex.sm="100" fxLayout="row" fxLayoutAlign="end center" style="font-weight: bold; padding: 10px; max-height: 120px; height:120px;" [ngStyle.xs]="{'width': '100%'}" [ngStyle.sm]="{'width': '100%'}">
            <div style="padding: 10px; max-height: 120px; height:120px; width: auto; float: right; border-radius: 10px 0 0 0;" 
                [style.background-color]="(role.isClient() && myTeam)?myTeam?.color:'turquoise'"
                [style.color]="services.getColorByBackgroundColor(myTeam?.color)">
                <label *ngIf="role.isClient()">{{(myTeam)?myTeam?.name:'Sin equipo'}}</label>
                <label *ngIf="role.isInstructor()" style="color: black;">Alumnos</label>
            </div>
            <div id="users-session" style="padding: 20px 50px 20px 50px; max-height: 120px; height:120px; width: auto;"  [ngStyle.xs]="{'width': 'inherit'}" [ngStyle.sm]="{'width': 'inherit'}" 
                [style.background-color]="(role.isClient() && myTeam)?myTeam?.color:'turquoise'"
                [style.color]="services.getColorByBackgroundColor(myTeam?.color)">
                <!--
                <img style="width: 15%;" src="assets/images/entrepeneurs/Group 796.png">
                <img style="width: 15%;" src="assets/images/entrepeneurs/facebook.png">
                <img style="width: 15%;" src="assets/images/entrepeneurs/instructor.png">
                <img style="width: 15%;" src="assets/images/entrepeneurs/youtube.png">
                -->
                <div *ngIf="users">
                    <button *ngIf="role.isClient() && users && users.length > 0" mat-icon-button class="w-40 h-40 m-2" style="color:white; height: 70px; width: 50px;">
                        <img class="bg-light img-bar-users" style="border-radius: 50%; width: 40px;height: 40px;"
                            [src]="rutaStorage+myInfoUserSession?.image_url"
                            onError="this.src='assets/images/perfil-usuario.png';">
                        <br/>
                        <small [style.color]="services.getColorByBackgroundColor(myTeam?.color)"><b>Yo</b></small>
                    </button>
                    <button *ngFor="let user of users" mat-icon-button class="w-40 h-40 m-2"
                        style="color:white; height: 70px; width: 50px;border-radius: 0px !important; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                        <img class="bg-light img-bar-users" style="border-radius: 50%;" [src]="rutaStorage+user?.image_url" onError="this.src='assets/images/perfil-usuario.png';">
                        <br/>
                        <small [style.color]="services.getColorByBackgroundColor(myTeam?.color)"><b>{{user.name}}</b></small>
                    </button>
                </div>
                <div *ngIf="(role.isInstructor() && (!users || users.length <= 0))">
                    <small> hay alumnos todavía.</small>
                </div>
                <div *ngIf="(role.isClient() && (!users || users.length <= 0))">
                    <small>No hay compañeros(as) en tu equipo.</small>
                </div>
                
            </div>
        </div>
    </div>
</div>
