import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Echo from 'laravel-echo';
import { API, ECHO_PROD, ECHO_TEST } from 'app/views/shared/api';

@Component({
  selector: 'app-dialog-store',
  templateUrl: './dialog-store.component.html',
  styleUrls: ['./dialog-store.component.css']
})
export class DialogStoreComponent implements OnInit, OnDestroy {
  products:Array<any>;
  echoEvent: any;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.echoEvent = new Echo((API.PROD)?ECHO_PROD:ECHO_TEST);
    this.products = data.products;
    this.setUpListeners();
   }

  ngOnInit(): void {
    //this.setUpListeners();
  }
  public setUpListeners(){
    //let codigoAcceso = localStorage.getItem('codigoAcceso');
    
    this.echoEvent.channel('channel-getProducts-'+this.data.team)
    .listen('GetProductsEvent', (response) => { 
      // datos ejecutado
      if (response != null) {
        let allProducts:Array<any> = response.data;        
        if (allProducts != null && Array.isArray(allProducts) ) {
          if (allProducts.length > 0) {
            if (response.idTeam == this.data.team ) {
              this.products = allProducts;
            }
          }
        }
      } else {
        this.products = new Array();
      }
    }).error((error) => {});
  }

  ngOnDestroy(){
    //let codigoAcceso = JSON.parse(localStorage.getItem('codigoAcceso'));
    //let codigoAcceso = localStorage.getItem('codigoAcceso');
    this.echoEvent.leaveChannel('channel-getProducts-'+this.data.team);
  }

}
