import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-accounting-excel',
  templateUrl: './accounting-excel.component.html',
  styleUrls: ['./accounting-excel.component.css']
})
export class AccountingExcelComponent implements OnInit, AfterViewInit {
  editField: string;
  public ingresosTotales :number;
  public costosComerciales :number;
  public gananciaNeta :number;
  public gastos :number;
  public excedente :number;
  public totales:any = {};

  
  
  selectDays = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30];
  tablaUno: Array<TablaUno> = [new TablaUno(1,"-",0),new TablaUno(2,"-",0),new TablaUno(3,"-",0),new TablaUno(4,"-",0),new TablaUno(5,"-",0),new TablaUno(5,"-",0),new TablaUno(6,"-",0),new TablaUno(7,"-",0),new TablaUno(7,"-",0),new TablaUno(8,"-",0)];
  tablaDos: Array<TablaDos> = [new TablaDos("-",0,0),new TablaDos("-",0,0),new TablaDos("-",0,0),new TablaDos("-",0,0),new TablaDos("-",0,0),new TablaDos("-",0,0),new TablaDos("-",0,0),new TablaDos("-",0,0),new TablaDos("-",0,0),new TablaDos("-",0,0) ];
  tablaTres: Array<TablaTres> = [new TablaTres("-","-","-",0),new TablaTres("-","-","-",0),new TablaTres("-","-","-",0),new TablaTres("-","-","-",0),new TablaTres("-","-","-",0),new TablaTres("-","-","-",0),new TablaTres("-","-","-",0),new TablaTres("-","-","-",0),new TablaTres("-","-","-",0),new TablaTres("-","-","-",0)  ];
  tablaCuatro: Array<TablaCuatro> = [new TablaCuatro("-",0),new TablaCuatro("-",0),new TablaCuatro("-",0),new TablaCuatro("-",0),new TablaCuatro("-",0),new TablaCuatro("-",0),new TablaCuatro("-",0),new TablaCuatro("-",0),new TablaCuatro("-",0),new TablaCuatro("-",0),new TablaCuatro("-",0)];
  tablaCinco: Array<TablaCinco> = [ new TablaCinco("-",0,"-"),new TablaCinco("-",0,"-"),new TablaCinco("-",0,"-"),new TablaCinco("-",0,"-"),new TablaCinco("-",0,"-"),new TablaCinco("-",0,"-"),new TablaCinco("-",0,"-"),new TablaCinco("-",0,"-"),new TablaCinco("-",0,"-"),new TablaCinco("-",0,"-") ];
  tablaSeis: Array<TablaSeis> = [ new TablaSeis("-",0,"-"),new TablaSeis("-",0,"-"),new TablaSeis("-",0,"-"),new TablaSeis("-",0,"-"),new TablaSeis("-",0,"-"),new TablaSeis("-",0,"-"),new TablaSeis("-",0,"-"),new TablaSeis("-",0,"-"),new TablaSeis("-",0,"-"),new TablaSeis("-",0,"-") ];
  constructor( public elem : ElementRef, public dialogRef: MatDialogRef<any>) { 
    this.totales.ingresos = 0;
    this.totales.costoscomerciales = 0;
    this.totales.ganancianeta = 0;
    this.totales.gastos = 0;
    this.totales.excedente = 0;
  }

  ngOnInit(): void {
    let accounting = localStorage.getItem('accounting');
    if(accounting){
      let data = JSON.parse(accounting);
      this.tablaUno = data.tablaUno;
      this.tablaDos = data.tablaDos;
      this.tablaTres = data.tablaTres;
      this.tablaCuatro = data.tablaCuatro;
      this.tablaCinco = data.tablaCinco;
      this.tablaSeis = data.tablaSeis;
      this.totales = data.totales;
    }
    this.dialogRef.backdropClick().subscribe(()=>{
      this.SaveSession();
    });
  }

  ngAfterViewInit(): void {
    this.validateLongitud();
    this.validateNumberLongitud();
    this.validateDate();
  }

  dismiss(){
    this.dialogRef.close();
    this.SaveSession();
  }

  public SaveSession(){
    let data = {
      tablaUno: this.tablaUno,
      tablaDos: this.tablaDos,
      tablaTres: this.tablaTres,
      tablaCuatro: this.tablaCuatro,
      tablaCinco: this.tablaCinco,
      tablaSeis: this.tablaSeis,
      totales: this.totales
    };
    localStorage.setItem('accounting',JSON.stringify(data));
  }

  public validateLongitud(){
    // Recortando a 20 caracteres todos aquellos campos que sean de tipo texto
    let elements = this.elem.nativeElement.querySelectorAll('.spantext');
    elements.forEach(function(i) {
        let valor:string = String(i.textContent);

        var len = valor.trim().length;
        if(len>20)
        {
          i.textContent = i.textContent.substr(0,20);
        }

    });
  }

  public validateNumberLongitud(){
    // Recortando a 10 caracteres todos aquellos campos que sean de tipo numero
    let elements = this.elem.nativeElement.querySelectorAll('.spannumbers');
    elements.forEach(function(i) {
        let valor:string = String(i.textContent);

        var len = valor.trim().length;
        if(len>10)
        {
          i.textContent = i.textContent.substr(0,10);
        }

        //Validando si es un numero
        if(!(isNaN(Number(valor)) == false && valor != "" && valor != null && valor != undefined)){
          i.textContent = 0;
          return;
        }

    });
  }

  public validateNumberTotales($event,campoId:number){

    let texto:string = $event.target.textContent;
    //Validando si es un numero
    if(!(isNaN(Number(texto)) == false && texto != null && texto != undefined)){
      $event.target.textContent = 0;
      texto = "0";
      return;
    }

    switch (campoId) {
      case 1:
        this.totales.ingresos = Number(texto);
        break;
      case 2:
        this.totales.costoscomerciales = Number(texto);
        break;
      case 3:
        this.totales.ganancianeta = Number(texto);
        break;
      case 4:
        this.totales.gastos = Number(texto);
        break;
      case 5:
        this.totales.excedente = Number(texto);
        break;
                                                      
      default:
        break;
    }
   
    var self = this;
    // Recortando a 10 caracteres todos aquellos campos que sean de tipo numero
    let elements = this.elem.nativeElement.querySelectorAll('.spannumberstotales');
    elements.forEach(function(i) {
        let valor:string = String(i.textContent);

        var len = valor.trim().length;
        if(len>10)
        {
          i.textContent = i.textContent.substr(0,10);

        }

        //Validando si es un numero
        if(!(isNaN(Number(valor)) == false && valor != null && valor != undefined)){
          i.textContent = 0;
          return;
        }

    });
    
  }

  public validateDate(){
    // Recortando a 10 caracteres todos aquellos campos que sean de tipo fecha (YYYY-mm-dd)
    let elements = this.elem.nativeElement.querySelectorAll('.spandates');
    elements.forEach(function(i) {
        let valor:string = String(i.textContent);

        var len = valor.trim().length;
        if(len>10)
        {
          i.textContent = i.textContent.substr(0,10);
        }

        //Validando si es un numero
        if(!(isNaN(new Date(valor).getDate()) == false)){
          let now = new Date();
          i.textContent = now.getFullYear()+'-'+ now.getMonth()+'-'+ now.getDay();
          return;
        }

    });
  }

  changeValue(id: number, property: string, event: any) {
    this.editField = event.target.textContent; 
  }

  updateList(id: number, property: string, event: any) {
    const editField = event.target.textContent || '-';
    this.tablaUno[id][property] = editField;
    event.target.textContent = editField;
  }

  updateListDos(id: number, property: string, event: any) {
    const editField = event.target.textContent || '-';
    this.tablaDos[id][property] = editField;
    event.target.textContent = editField;
  }

  updateListTres(id: number, property: string, event: any) {
    const editField = event.target.textContent || '-';
    this.tablaTres[id][property] = editField;
    event.target.textContent = editField;
  }

  updateListCuatro(id: number, property: string, event: any) {
    const editField = event.target.textContent || '-';
    this.tablaCuatro[id][property] = editField;
    event.target.textContent = editField;
  }

  updateListCinco(id: number, property: string, event: any) {
    const editField = event.target.textContent || '-';
    this.tablaCinco[id][property] = editField;
    event.target.textContent = editField;
  }

  updateListSeis(id: number, property: string, event: any) {
    const editField = event.target.textContent || '-';
    this.tablaSeis[id][property] = editField;
    event.target.textContent = editField;
  }

  public isDate(data: any){
    return isNaN(new Date(data).getDate()) == false;
  }

  public isNumber(data_: any){
    let data:any = String(data_).trim();
    return isNaN(data) == false && data != "" && data != null && data != undefined;
  }

}

class TablaUno {
  public fecha:number;
  public dineroVinoDe:string;
  public total:number;
  constructor(fecha: number, dineroVinoDe: string, total: number) {
    this.fecha = fecha;
    this.dineroVinoDe = dineroVinoDe;
    this.total = total;
  }
}

class TablaDos{
  public negocios:string;
  public gastos:number;
  public ahorros:number;

  constructor(negocios:string,gastos:number, ahorros:number){
    this.negocios = negocios;
    this.gastos = gastos;
    this.ahorros = ahorros;
  }
}

class TablaTres{
  public fecha:string;
  public paguePor:string;
  public negocios:string;
  public gastos:number;
  constructor( fecha:string, paguePor:string, negocios:string, gastos:number ){
    this.fecha = fecha;
    this.paguePor = paguePor;
    this.negocios = negocios;
    this.gastos = gastos;
  }
}

class TablaCuatro{
  public ahorroUno:string;
  public ahorroDos:number;
  constructor(ahorroUno:string, ahorroDos:number){
    this.ahorroUno = ahorroUno;
    this.ahorroDos = ahorroDos;
  }
}

class TablaCinco{
  public quienMeDebe:string;
  public cantidad:number;
  public fechaDePago: string;

  constructor(quienMeDebe:string, cantidad:number,fechaDePago: string ){
    this.quienMeDebe = quienMeDebe;
    this.cantidad = cantidad;
    this.fechaDePago = fechaDePago;
  }
}

class TablaSeis{
  public aQuien:string;
  public cantidad:number;
  public fechaDePago: string;

  constructor(aQuien:string, cantidad:number, fechaDePago: string ){
    this.aQuien = aQuien;
    this.cantidad = cantidad;
    this.fechaDePago = fechaDePago;
  }

}
